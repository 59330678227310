import { genericRetryStrategy } from './../../../../../mypa-fe-common/src/lib/utils/generic-retry-strategy';
import { ToastrService } from 'ngx-toastr';
import {
    Ente, FieldBean, manageError, OverlaySpinnerService, TipoDovuto, UserService, validateFormFun
} from 'projects/mypa-fe-common/src/public-api';
import { Observable, Subscription } from 'rxjs';
import { flatMap, map, startWith } from 'rxjs/operators';

import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { EnteService } from '../../services/ente.service';
import { RecaptchaService } from '../../services/recaptcha.service';
import { SpontaneoService } from '../../services/spontaneo.service';

@Component({
  selector: 'app-spontaneo',
  templateUrl: './spontaneo.component.html',
  styleUrls: ['./spontaneo.component.scss']
})
export class SpontaneoComponent implements OnInit, OnDestroy {

  @ViewChild('enteTipoDirective') enteTipoDirective;
  @ViewChild('insertFormDirective') insertFormDirective;

  enteOptions: Ente[];
  enteFilteredOptions: Observable<Ente[]>;

  tipoDovutoOptionsMap: Map<String, TipoDovuto[]>;
  tipoDovutoOptions: TipoDovuto[];
  tipoDovutoFilteredOptions: Observable<TipoDovuto[]>;
  previousEnte: Ente;

  private codIpaEnteLanding: string;
  private codTipoDovutoLanding: string;

  hasSearched: boolean = false;
  blockingError: boolean = false;

  enteTipo: FormGroup;
  enteTipoErrors = {};
  private logged:boolean;

  private formChangesSub: Subscription;
  private enteTipoChangeSub: Subscription;
  private loggedUserSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private spontaneoService: SpontaneoService,
    private enteService: EnteService,
    private userService: UserService,
    private toastrService: ToastrService,
    private overlaySpinnerService: OverlaySpinnerService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private recaptchaService: RecaptchaService,
    router: Router,
  ) {
    this.enteTipo = this.formBuilder.group({
      ente: [null, [Validators.required, this.enteValidator]],
      tipoDovuto: [null, [Validators.required, this.tipoDovutoValidator]],
    });

    this.formChangesSub = this.enteTipo.valueChanges.subscribe(validateFormFun(this.enteTipo, this.enteTipoErrors));

    //parameters from landing "spontaneo"
    this.codIpaEnteLanding = router.getCurrentNavigation()?.extras?.state?.codIpaEnte;
    this.codTipoDovutoLanding = router.getCurrentNavigation()?.extras?.state?.codTipoDovuto;
  }

  ngOnInit(): void {
    //init recaptcha if anonymous
    this.loggedUserSub = this.userService.getLoggedUserObs().subscribe(loggedUser => {
      if(loggedUser){
        this.logged = true;
      } else{
        this.logged = false;
        this.recaptchaService.init(this.renderer);
      }
    });



    //load enti from backend
    this.spontaneoService.getAllEntiSpontanei().subscribe(enti => {
      this.enteOptions = enti;
      //init autocomplete feature of ente field
      this.enteFilteredOptions = this.enteTipo.get('ente').valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' || !value ? value : value.deNomeEnte),
          map(deNomeEnte => deNomeEnte ? this._enteFilter(deNomeEnte) : this.enteOptions.slice())
        );

      //Set ente, tipoDovuto by the query params if exist.
      if (this.codIpaEnteLanding) {
        const ente = this.enteOptions.find(e => e.codIpaEnte === this.codIpaEnteLanding);
        if (ente) {
          this.enteTipo.controls['ente'].setValue(ente);
          if (!this.tipoDovutoOptionsMap.has(ente.codIpaEnte)) {
            this.enteService.getListTipoDovutoByEnte(ente).subscribe(tipiDovuto => {
              this.tipoDovutoOptionsMap.set(ente.codIpaEnte, tipiDovuto);
              this.tipoDovutoOptions = this.tipoDovutoOptionsMap.get(ente.codIpaEnte);
              if (this.codTipoDovutoLanding) {
                const tipoDovuto = this.tipoDovutoOptions.find(tipo => tipo.codTipo === this.codTipoDovutoLanding);
                if (tipoDovuto) {
                  this.enteTipo.controls['tipoDovuto'].setValue(tipoDovuto);
                  //timeout needed to allow for complete form initialization
                  this.onSubmit();
                }
              }
            }, manageError('Errore caricando l\'elenco dei tipi dovuto', this.toastrService, ()=>{this.blockingError=true}) );
          }
        }
      }

      }, manageError('Errore caricando l\'elenco degli enti', this.toastrService, ()=>{this.blockingError=true}) );

    this.tipoDovutoOptionsMap = new Map();
    this.enteTipoChangeSub = this.enteTipo.controls['ente'].valueChanges.subscribe(value => {
      if(value && value.mygovEnteId){
        this.enteTipo.controls['tipoDovuto'].setValue(null);
        if(!this.tipoDovutoOptionsMap.has(value.codIpaEnte)){
          this.enteService.getListTipoDovutoByEnte(value).subscribe(tipiDovuto => {
            this.tipoDovutoOptionsMap.set(value.codIpaEnte, tipiDovuto);
            this.tipoDovutoOptions = this.tipoDovutoOptionsMap.get(value.codIpaEnte);
            this.tipoDovutoFilteredOptions = this.enteTipo.get('tipoDovuto').valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' || !value ? value : value.deTipo),
              map(deTipoDovuto => deTipoDovuto ? this._tipoDovutoFilter(deTipoDovuto) : this.tipoDovutoOptions.slice())
            );
          }, manageError('Errore caricando l\'elenco dei tipi dovuto', this.toastrService, ()=>{this.blockingError=true}) );
        } else {
          this.tipoDovutoOptions = this.tipoDovutoOptionsMap.get(value.codIpaEnte);
          this.tipoDovutoFilteredOptions = this.enteTipo.get('tipoDovuto').valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' || !value ? value : value.deTipo),
            map(deTipoDovuto => deTipoDovuto ? this._tipoDovutoFilter(deTipoDovuto) : this.tipoDovutoOptions.slice())
          );
        }
      } else {
        this.tipoDovutoOptions = [];
        this.enteTipo.controls['tipoDovuto'].setValue(null);
      }
    });
  }

  ngOnDestroy():void {
    this.formChangesSub?.unsubscribe();
    this.enteTipoChangeSub?.unsubscribe();
    this.loggedUserSub?.unsubscribe();
    this.recaptchaService.deactivate();
  }

  enteDisplayFn(ente: Ente): string {
    return ente ? ente.deNomeEnte : '';
  }

  private _enteFilter(name: string): Ente[] {
    const filterValue = name.toLowerCase();
    return this.enteOptions.filter(option => option.deNomeEnte.toLowerCase().indexOf(filterValue) !== -1);
  }

  private enteValidator = (control: AbstractControl):{[key: string]: boolean} | null => {
    return ( !control.value || control.value.mygovEnteId != null ) ? null : {'invalid': true};
  };

  tipoDovutoDisplayFn(tipoDovuto: TipoDovuto): string {
    return tipoDovuto ? tipoDovuto.deTipo : '';
  }

  private _tipoDovutoFilter(name: string): TipoDovuto[] {
    const filterValue = name.toLowerCase();
    return this.tipoDovutoOptions.filter(option => option.deTipo.toLowerCase().indexOf(filterValue) !== -1);
  }

  private tipoDovutoValidator = (control: AbstractControl):{[key: string]: boolean} | null => {
    return ( !control.value || control.value.mygovEnteTipoDovutoId != null ) ? null : {'invalid': true};
  };

  onReset(){
    this.enteTipo.reset();
    this.hasSearched = false;
    //this.enteTipoDirective.reset();
  }

  onSubmit() {
    const i = this.enteTipo.value;
    let redirectLink = (i.tipoDovuto as TipoDovuto).deUrlPagamentoDovuto?.trim();
    if (redirectLink && redirectLink.length > 0) {
      window.open(redirectLink);
    } else {
      this.hasSearched = true;
      const spinner = this.overlaySpinnerService.showProgress(this.elementRef);
      let initializeFormFun;
      if(this.logged)
        initializeFormFun = this.spontaneoService.initializeForm(i.ente, i.tipoDovuto);
      else
        initializeFormFun = this.recaptchaService.submitToken('initializeForm').pipe(
          flatMap(token => this.spontaneoService.initializeFormAnonymous(i.ente, i.tipoDovuto, token))
        );

      initializeFormFun.subscribe(data => {
        this.currentEnte = i.ente as Ente;
        this.currentTipoDovuto = i.tipoDovuto as TipoDovuto;
        this.fieldBeans = data.fieldBeans;
        this.importoPrefissato = data.importo ? Number(data.importo) : null;
        this.overlaySpinnerService.detach(spinner);
      }, manageError('Errore inizializzando il form', this.toastrService, () => {this.overlaySpinnerService.detach(spinner)}) );
    }
  }

  /* -- Members for spontaneo-dynamo(my-dynamo) -- */
  currentTipoDovuto: TipoDovuto;
  currentEnte: Ente;
  fieldBeans: FieldBean[];
  importoPrefissato: number;

  public isLoaded: boolean;


  /* !-- Members for spontaneo-dynamo(my-dynamo) -- */

}

