import { Subscription } from 'rxjs';

import { FormGroup, ValidatorFn } from '@angular/forms';

const __formValidationMessages = {
  'required': 'Campo obbligatorio',
  'pattern': 'Valore non corretto',
  'invalid': 'Valore non valido',
  'email': 'Email non valida',
  'matDatepickerParse': 'Data non valida',
  'dateRange': '"Data a" precede "Data da"',
  'matDatepickerParseRangeFrom': '"Data da" non valida',
  'matDatepickerParseRangeTo': '"Data a" non valida',
  'requiredRangeFrom': '"Data da" obbligatoria',
  'requiredRangeTo': '"Data a" obbligatoria',
  'dateMinFrom': '"Data da" precedente alla data minima ammessa',
  'dateMaxFrom': '"Data da" successiva alla data massima ammessa',
  'dateMinTo': '"Data a" precedente alla data minima ammessa',
  'dateMaxTo': '"Data a" successiva alla data massima ammessa',
}

export function validateForm(formGroup:FormGroup, data:Object, formErrors?:{}, formValidationMessages?:{}):{} {
  if(!formErrors)
    formErrors={};
  if(formGroup && data)
    Object.keys(data).forEach(field => {
      const control = formGroup.get(field);
      formErrors[field] = '';
      if(control && !control.valid){
        for(const key in control.errors) {
          if(control.errors.hasOwnProperty(key)){
            formErrors[field] += (formValidationMessages?.[field]?.[key] ?? formValidationMessages?.[key]
                                  ?? __formValidationMessages[key] ?? key) + ' ';
            //console.log('field:',field,' - key:',key,' - formErrors[field]:',formErrors[field])
          }
        }
      }
    });
  return formErrors;
}

export function subscribeValidateForm(formGroup:FormGroup, formErrors?:{}, formValidationMessages?:{}, callbackFun?:(data:Object)=>void): Subscription{
  return formGroup.valueChanges.subscribe( (data:Object) => {
    callbackFun?.(data);
    return validateForm(formGroup, data, formErrors, formValidationMessages);
  } )
}

export function subscribeValidateFormAsync(formGroup:FormGroup, formErrors?:{}, formValidationMessages?:{}, callbackFun?:(data:Object)=>void): Subscription{
  return formGroup.valueChanges.subscribe( (status:Object) => {
    const data = formGroup.value;
    callbackFun?.(data);
    return validateForm(formGroup, data, formErrors, formValidationMessages);
  } )
}

export function validateFormFun(formGroup:FormGroup, formErrors?:{}, formValidationMessages?:{}, callbackFun?:(data:Object)=>void):(any)=>{} {
  return (data:Object) => {
    callbackFun?.(data);
    return validateForm(formGroup, data, formErrors, formValidationMessages);
  }
}

export function validateFormFunAsync(formGroup:FormGroup, formErrors?:{}, formValidationMessages?:{}, callbackFun?:(data:Object)=>void):(any)=>{} {
  return (status:Object) => {
    const data = formGroup.value;
    callbackFun?.(data);
    return validateForm(formGroup, data, formErrors, formValidationMessages);
  }
}

export function conditionalValidator(
  predicate: () => boolean,
  validator: ValidatorFn,
  errorNamespace?: string
): ValidatorFn {
  return formControl => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError;
    }
    return error;
  };
}
