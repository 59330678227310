import {
    ApiInvokerService, BaseUrlService, Ente, TipoDovuto
} from 'projects/mypa-fe-common/src/public-api';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnteService {

  private entiForLogo = new Map<string, Ente>();

  constructor(
    private baseUrlService: BaseUrlService,
    private apiInvokerService: ApiInvokerService
  ) { }

  getAllEnti(): Observable<Ente[]> {
    return this.apiInvokerService.get<Ente[]>(this.baseUrlService.getPubCittadinoUrl() + 'enti');
  }

  getLogoEnte(codIpaEnte: string, thumbnail:boolean = true): Observable<string> {
    return this.entiForLogo.has(codIpaEnte) ?
      of(Ente.logo(this.entiForLogo.get(codIpaEnte))) :
      this.apiInvokerService.get<Ente>(this.baseUrlService.getPubCittadinoUrl() + 'enti/byCodIpa/' + codIpaEnte)
        .pipe(
          map(ente => {
            this.entiForLogo.set(codIpaEnte, ente);
            return Ente.logo(ente);
          })
        );
  }

  getListTipoDovutoByEnte(ente: Ente): Observable<TipoDovuto[]> {
    return this.apiInvokerService.get<TipoDovuto[]>(this.baseUrlService.getPubCittadinoUrl() + 'enti/' + ente.mygovEnteId + '/tipiDovuto');
  }

}
