import { map } from 'rxjs/operators';
import { MypaVolontariatoService } from './../../../../mypa-fe-cittadino/src/app/services/mypa-volontariato/mypa-volontariato.service';
import { UserService, environment } from 'projects/mypa-fe-common/src/public-api';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class AccessGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    private mypaVolontariatoService: MypaVolontariatoService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    /* const requiresLogin = route.data.requiresLogin || false;
    const auth = !requiresLogin || this.userService.isLogged() &&
                  (!route.data.menuItem?.roles || route.data.menuItem.roles?.some(role => this.userService.isRoleAuthorized(role)));
    console.log('routing to: '+route+' - auth: '+auth);
    if (!auth) {
      this.router.navigate(['home']);
      return false;
    } else {
      return true;
    } */
    return this.mypaVolontariatoService.isLogged().pipe(map(ret => {
      if (ret == true) {
        return true;
      }
      else {
        window.location.replace(`${environment.samlLogin}?redirectUrl=${btoa(encodeURIComponent(window.location.href))}`);
        return false;
      }
    }));
  }
}
