import {
  Injector,
  Pipe,
  PipeTransform
} from '@angular/core';


@Pipe({
name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {

  public constructor(private injector: Injector) {
  }

  transform(value: any, pipeToken: any, pipeArgs: any[]): any {
      if (!pipeToken) {
          return value;
      }
      else {
          let pipe = this.injector.get(pipeToken);
          pipeArgs = pipeArgs || [];
          try{
            return pipe.transform(value, ...pipeArgs);
          } catch(error){
            console.warn(`Dynamic pipe [${pipeToken?.constructor?.name}] error on value [${value}]: ${error}`);
            return value;
          }
      }
  }
}
