<div mat-dialog-title fxLayout="row" fxLayoutAlign="start start">
  <h2>Dettaglio dati</h2>
  <span class="flex-spacer"></span>
  <button mat-flat-button mat-dialog-close><fa-icon [icon]="iconTimes" size="lg"></fa-icon></button>
</div>

<mat-dialog-content class="mat-typography">
  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <div>
        <div gdGap="10px" gdColumns="repeat(auto-fill, minmax(300px, 1fr))">
          <div *ngFor="let detail of details | detailfilter:detailFilterInclude:detailFilterExclude">
            <span class="mat-body-2 break-word">{{detail.key}}</span><br>
            <span class="break-word">{{detail.value || '&nbsp;'}}</span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-flat-button color="accent"
    *ngFor="let action of actionColumn.enabledActions(tableId, parentRef, element) | slice:1"
    (click)= "action.click(tableId, element, parentRef, $event)">
    <span>{{action.tooltip}}</span>
  </button>
</mat-dialog-actions>
