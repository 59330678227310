import { ToastrService } from 'ngx-toastr';
import { UserService } from 'projects/mypa-fe-common/src/public-api';

import { Component, OnInit } from '@angular/core';
import {
    faFacebookSquare, faInstagramSquare, faTwitterSquare, faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  iconPhone = faPhone;
  iconEnvelope = faEnvelope;

  iconFacebook = faFacebookSquare;
  iconInstagram = faInstagramSquare;
  iconYoutube = faYoutubeSquare;
  iconTwitter = faTwitterSquare;

  constructor(
    private userService: UserService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  showAppInfo(){
    this.userService.getAppInfoString().subscribe(appInfoString => {
      this.toastr.info(appInfoString, 'Versione applicazione',{
        disableTimeOut: true,
        enableHtml: true,
        positionClass:'toast-top-center'
      });
      return null;
    })
  }

}
