import { Posizione, Target } from './../../model/model-mypa-volontariato';
import { ActivatedRoute } from '@angular/router';
import { MypaVolontariatoService } from './../../services/mypa-volontariato/mypa-volontariato.service';
import { faArrowLeft, faBullseye, faFlag, faFolder } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dettaglio-posizione-aperta',
  templateUrl: './dettaglio-posizione-aperta.component.html',
  styleUrls: ['./dettaglio-posizione-aperta.component.scss']
})
export class DettaglioPosizioneApertaComponent implements OnInit {

  public iconArrowLeft: any = faArrowLeft;
  public iconFolder: any = faFolder;
  public iconFlag: any = faFlag;
  public iconBullseye: any = faBullseye;

  public id: number;
  public posizione: Posizione = new Posizione();
  public isIdCorretto: boolean = true;
  public ente: string;

  constructor(private myPaVolontariatoService: MypaVolontariatoService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = Number(this.activatedRoute.snapshot.paramMap.get("id"));
    this.ente = this.activatedRoute.snapshot.paramMap.get("ente");
    this.dettaglioPosizioneById();
  }

  public dettaglioPosizioneById(): void {
    this.myPaVolontariatoService.dettaglioPosizioneById(this.id, this.ente).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          /* prelevamento campi da specifici punti del dto --> */
          response.entity.comune = response.entity.denominazione_ente;
          response.entity.nomePosizione = response.entity.profiloObj.id == 8 ? response.entity.descrizione_profilo : response.entity.profiloObj.descrizione;
          /* <-- */

          /* popolamento target --> */
          let listaTarget: Target[] = response.entity.servizioObj.target;
          response.entity.listaTarget = [];
          listaTarget.forEach(
            target => {
              if (target.id == 38 || target.id == 50) {
                response.entity.listaTarget.push(target.descrizione_altro);
              }
              else {
                response.entity.listaTarget.push(target.descrizione);
              }
            }
          );
          /* <-- */

          this.posizione = response.entity;
        }
        else {
          this.isIdCorretto = false;
        }
      }
    );
  }

  public candidati(posizione: Posizione): void {
    window.open(`${posizione.urlCandidatura}${posizione.ente}/${posizione.userRegistryId}?id=${posizione.id}`, '_blank');
    /* window.location.href = `${posizione.urlCandidatura}${posizione.ente}/${posizione.userRegistryId}?id=${posizione.id}`; */
  }

  public indietro(): void {
    window.location.href = "https://google.com";
  }

}
