<div class="container"> 

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div>
      <h1 class="mat-h1">Avvisi di Pagamento</h1>
    </div>
    <div>
      <h2 class="mat-h2">Se hai ricevuto un avviso di pagamento compila il seguente form</h2>
    </div>

    <div class="w100 mypay-search-form" aria-level="1">
      <form novalidate #sForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
          <mat-label id="sr-IUV" class="sr-only">Codice avviso / IUV</mat-label>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label>Codice avviso / IUV</mat-label>
            <input aria-labelledby="sr-IUV" matInput formControlName="numeroAvviso" (focusout)="removeSpaces('numeroAvviso')">
            <mat-error *ngIf="formErrors['numeroAvviso']">{{ formErrors.numeroAvviso }}</mat-error>
          </mat-form-field>
          <div *ngIf="logged" fxFlex="100%" fxFlex.gt-sm="initial" fxLayout="column" class="tipo-persona-group">
            <span fxFlex class="mat-caption">Intestatario</span>
            <div fxFlex>
            <mat-button-toggle-group (change)="onChangeTipoPersona($event)" formControlName="tipoPersona">
              <mat-button-toggle value="logged" >{{ loggedUserName }}</mat-button-toggle>
              <mat-button-toggle value="other">Altro soggetto</mat-button-toggle>
            </mat-button-toggle-group></div>
          </div>
          <mat-label id="sr-CodFis" class="sr-only">Codice fiscale / partita IVA intestatario</mat-label>
          <mat-form-field class="cod-fiscale" fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label aria-hidden="true">Codice fiscale / partita IVA intestatario</mat-label>
            <input aria-labelledby="sr-CodFis" matInput formControlName="codIdUnivoco" (focusout)="removeSpaces('codIdUnivoco')">
            <mat-error *ngIf="formErrors['codIdUnivoco']">{{ formErrors.codIdUnivoco }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">

          <button type="submit" mat-flat-button [disabled]="form.invalid || blockingError" color="accent">Cerca</button>
        </div>

      </form>
    </div>

    <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
    <my-pay-table-cittadino [tableData]="tableData" [tableColumns]="tableColumns" [parentRef]="this"
      [rowStyle]="rowStyleFun">
    </my-pay-table-cittadino>

  </div>
</div>
