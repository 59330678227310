export class Struttura {
    public id: number;
    public denominazione: string;
    public descrizione: string;
    public id_albo: string;
    public sede_leg_indirizzo: string;
    public sede_leg_citta: string;
    public sede_leg_provincia: string;
    public sede_leg_cap: string;
    public codice_fiscale: string;
    public fax: string;
    public pec: string;
    public telefono: string;
    public email: string;
    public rappresentante_legale_codfisc: string;
    public rappresentante_legale_cognome: string;
    public rappresentante_legale_nome: string;
    public sito_web: string;
    public province_territorio: string;
    public descrizione_territorio: string;
    public tipoStruttura: TipoStruttura;
    public data_inizio_validita: Date;
    public data_fine_validita: Date;
    public data_inserimento: Date;
    public data_modifica: Date;
    public ente: string;
    public utente_loggato: string;
    public codice_associazione: string;
    public settori: Settore[];
    public servizi: Servizi[];
    public abilitazioneMypa: boolean;
    public statoPubblicazione: number;
    public statoPubblicazioneObj: StatoPubblicazione;

    /* campi utili solamente al front-end */
    public details: any[];
    public doveOpera: string[];
    public giorniDellaSettimana: Set<string>;
    public attivita: Set<string>;
    public orariAttivita: Set<string>;
    public target: Set<string>;
}

export class Servizi {
    public id: number;
    public struttura: number;
    public strutturaObj: Struttura;
    public descrizione_servizio: string;
    public province_territorio: string;
    public descrizione_territorio: string;
    public accesso_servizio: string;
    public periodo_erogazione: string;
    public giorni_attivita: string;
    public ore_attivita: string;
    public note: string;
    public volume_utenti: number;
    public interventi_effettuati: number;
    public data_aggiornamento_volumi: Date;
    public data_aggiornamento_interventi: Date;
    public descrizione_intervento: string;
    public data_inizio_validita: Date;
    public data_fine_validita: Date;
    public data_inserimento: Date;
    public data_modifica: Date;
    public ente: string;
    public utente_loggato: string;
    public codice_associazione: string;
    public attivita: Attivita[];
    public target: Target[];
    public beneficiari: string[];
    public beneficiariObj: Beneficiario[];
}

export class Attivita {
    public id: number;
    public codice: string;
    public descrizione: string;
    public descrizione_altro: string;
}

export class Target {
    public id: number;
    public codice: string;
    public descrizione: string;
    public descrizione_altro: string;
    public tipo: string;
}

export class Beneficiario {
    public codice_fiscale: string;
    public nome: string;
    public cognome: string;
    public residenza: string;
    public codiceFamiglia: string;
    public nucleoFamiliare: AnagraficaNucleoFamiliare[];
}

export class AnagraficaNucleoFamiliare {
    public idAnagraficaCittadino: number;
    public nome: string;
    public cognome: string;
    public sesso: string;
    public dataNascita: Date;
    public codiceFiscale: string;
    public cittadinanza: string;
    public indirizzoResidenza: string;
    public comuneResidenza: string;
    public descrizioneComuneResidenza: string;
    public rapportoParentela: string;
    public rapportoParentelaDescrizione: string;
}

export class TipoStruttura {
    public id: number;
    public codice: string;
    public descrizione: string;
}

export class Settore {
    public id: number;
    public codice: string;
    public descrizione: string;
    public descrizione_altro: string;
}

export class Result<T> {
    public esitoOperazione: EsitoOperazione;
    public entity: T;
}

export class EsitoOperazione {
    public codiceEsito: string;
    public descrizioneEsito: string;
}

export class PosizioneInfoFilter {
    public descrizione_comune: string;
    public tipo_struttura: number;
    public denominazione: string;
    public settore: number;
    public attivita: number;
    public target: number;
    public ente: string;
    public prima_ricerca: boolean;
    public area_urbana: string;
}

export class Page<T> {
    public content: T[];
    public empty: boolean;
    public first: boolean;
    public last: boolean;
    public number: number;
    public numberOfElements: number;
    public pageable: any;
    public size: number;
    public sort: any;
    public totalElements: number;
    public totalPages: number;
}

export class Posizione {
    public id: number;
    public servizio: number;
    public settore: number;
    public descrizione_settore: string;
    public attivita: number;
    public descrizione_attivita: string;
    public referente_struttura: string;
    public servizioObj: Servizi;
    public settoreObj: Settore;
    public attivitaObj: Attivita;
    public province_territorio: string;
    public descrizione_territorio: string;
    public periodo_erogazione: string;
    public giorni_attivita: string;
    public ore_attivita: string;
    public disponibilita_ore: string;
    public risorse_richieste: number;
    public accoglienza: string;
    public supporto: string;
    public benefici: string;
    public codice_associazione: string;
    public contatti: string;
    public stato: number;
    public statoObj: Stato;
    public note: string;
    public data_inizio_validita: Date;
    public data_fine_validita: Date;
    public data_inserimento: Date;
    public data_modifica: Date;
    public ente: string;
    public denominazione_ente: string;
    public utente_loggato: string;
    public profilo: number;
    public descrizione_profilo: string;
    public profiloObj: Profilo;
    public requisiti: Requisito[];
    public descrizione: string;
    public abilitazioneMypa: boolean;
    public statoPubblicazione: number;
    public statoPubblicazioneObj: StatoPubblicazione;
    public userRegistryId: string;
    public urlCandidatura: string;

    /* campi utili solamente al front-end */
    public comune: string;
    public nomePosizione: string;
    public listaTarget: string[];
}

export class Stato {
    public id: number;
    public codice: string;
    public descrizione: string;
}

export class Profilo {
    public id: number;
    public codice: string;
    public descrizione: string;
    public descrizione_altro: string;
}

export class Requisito {
    public id: number;
    public codice: string;
    public descrizione: string;
    public descrizione_altro: string;
    public tipo_abilitazione: string;
    public tipo_certificazione: string;
    public tipo_patente: string;
}

export class Territorio {
    public id: number;
    public denominazione: string;
    public denominazione_provincia: string;
    public codice_istat: string;
    public codice_mf: string;
    public sigla: string;
    public denominazione_area_urbana: string;
    public codice_area_urbana: string;
    public ente: string;
    public areaUrbana: AreaUrbana;
}

export class AreaUrbana {
    public id: number;
    public denominazione: string;
    public codice: string;
}

export class StatoPubblicazione {
    public id: number;
    public codice: string;
    public descrizione: string;
}

export class Consenso {
    public cf_operatore: string;
    public denominazione_associazione: string;
    public consenso: boolean;
}