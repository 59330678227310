import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivationEnd, NavigationEnd, Route, Router, UrlSegment } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import { Breadcrumb } from '../model/breadcrumb';
import { MenuItem } from '../model/menu-item';

@Injectable({
  providedIn: 'root'
})
export class MyPayBreadcrumbsService {

  private breadcrumbs: Breadcrumb[] = [];
  private breadcrumbsObs = new BehaviorSubject<Breadcrumb[]>(this.breadcrumbs);

  private menuItemMap:{[key:number]:MenuItem};

  constructor(
    private router: Router,
  ) {
    let route: Route;
    let urlSegments: UrlSegment[];
    router.events.pipe(
      filter( event => event instanceof NavigationEnd || event instanceof ActivationEnd)
    ).subscribe( event => {
      if(event instanceof ActivationEnd) {
        route = event.snapshot.routeConfig;
        urlSegments = event.snapshot.url;
      } else if(event instanceof NavigationEnd){
        if(router.getCurrentNavigation().trigger === 'popstate' || router.getCurrentNavigation().extras?.replaceUrl)
          this.breadcrumbs.pop();
        if(route.data?.breadcrumb && (this.breadcrumbs.length===0 || router.getCurrentNavigation().trigger !== 'popstate')){
          const breadcrumb = new Breadcrumb();
          if(route.data?.breadcrumb?.home){
            this.breadcrumbs = [];
            window.history.pushState(null, null, urlSegments.join('/'));
          }
          breadcrumb.label = route.data?.breadcrumb?.label || route.path;
          breadcrumb.home = route.data?.breadcrumb?.home || false;
          if((this.menuItemMap?.[route.data?.menuItemId]?.icon as IconDefinition)?.icon){
            const icon = this.menuItemMap?.[route.data?.menuItemId]?.icon as IconDefinition;
            if(_.findLast(this.breadcrumbs, b => b.icon!=null)?.icon !== icon)
              breadcrumb.icon = icon;
          }
          route = null;
          urlSegments = null;
          breadcrumb.originalLabel = breadcrumb.label;
          breadcrumb.originalIcon = breadcrumb.icon;
          this.breadcrumbs.push(breadcrumb);
        }
        this.breadcrumbsObs.next(this.breadcrumbs);
      }
    } );
  }

  public setMenuItemMap(menuItemMap:{[key:number]:MenuItem}){
    this.menuItemMap = menuItemMap;
  }

  public getBreadcrumbs(): Observable<Breadcrumb[]>{
    return this.breadcrumbsObs;
  }

  public resetBreadcrumbs(): void{
    this.breadcrumbs = this.breadcrumbs.slice(0,this.breadcrumbs?.[0]?.home ? 1 : 0);
    this.breadcrumbsObs.next(this.breadcrumbs);
  }

  public updateCurrentBreadcrumb(label: string, icon?: IconDefinition): void{
    setTimeout(()=>{
      if(this.breadcrumbs.length<1)
        return;
      const currentBreadcumb = this.breadcrumbs[this.breadcrumbs.length-1];
      currentBreadcumb.label = label;
      if(icon)
        currentBreadcumb.icon = icon;
    }, 0);
  }

  public resetCurrentBreadcrumb(): void{
    setTimeout(()=>{
      if(this.breadcrumbs.length<1)
        return;
      const currentBreadcumb = this.breadcrumbs[this.breadcrumbs.length-1];
      currentBreadcumb.label = currentBreadcumb.originalLabel;
      currentBreadcumb.icon = currentBreadcumb.originalIcon;
    }, 0);
  }

  public goBack(times: number): void{
    if(times<=0)
      return;
    _.times(times-1, ()=>this.breadcrumbs.pop());
    window.history.go(-times);
    this.breadcrumbsObs.next(this.breadcrumbs);
  }

}
