import { Observable, throwError } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlreadyManagedError } from '../model/already-managed-error';

@Injectable({
  providedIn: 'root'
})
export class ProcessHTTPMsgService {

  constructor() { }

  public handleError(error: HttpErrorResponse) {

    //for this kind of errors we just relaunch it as it is (component will deal with it)
    if(error instanceof AlreadyManagedError)
      return throwError(error);

    let errMsg: string;

    if(error.status==500){
      errMsg = 'Errore interno di sistema: ';
    } else if(error.status==400){
      errMsg = 'Dati non validi: ';
    } else if(error.status==401){
      errMsg = 'Non autorizzato: ';
    } else if(error.status==404){
      errMsg = 'Risorsa non trovata: '
    } else if(error.status==604){
      errMsg = 'Risorsa non trovata  (604): '
    } else {
      const status = error.status ? ('['+error.status+']') : '';
      errMsg = 'Errore di sistema '+status+': ';
    };

    if(error.error instanceof Blob){
      const reader: FileReader = new FileReader();
      const obs = new Observable((observer: any) => {
        reader.onloadend = (e) => {
          const message = reader.result?.toString() || 'Errore di sistema';
          errMsg = ProcessHTTPMsgService.trimMessage(errMsg, message);
          observer.error(errMsg);
          observer.complete();
        }
      });
      reader.readAsText(error.error);
      return obs;
    } else {
      const message = error.error?.message || error.error?.errorCode ||error.message;
      errMsg = ProcessHTTPMsgService.trimMessage(errMsg, message);
      return throwError(errMsg);
    }
  }

  public skipHandleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  private static trimMessage(errMsg:string, message:string){
    errMsg += message?.substr(0,150);
    if(message?.length>150)
      errMsg += "...";
    return errMsg;
  }
}
