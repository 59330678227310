import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSizePipe'
})
export class FileSizePipe implements PipeTransform {
    private units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB'];

    transform(bytes: number = 0, precision: number = 2): string {
        // tslint:disable-next-line:curly
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

        let unit = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }

        return bytes.toFixed(unit==0?0:precision) + ' ' + this.units[unit];
    }
}
