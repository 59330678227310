import { ActivatedRoute } from '@angular/router';
import { MypaVolontariatoService } from './../../services/mypa-volontariato/mypa-volontariato.service';
import { Territorio, TipoStruttura, Settore, Attivita, Target, PosizioneInfoFilter, Posizione, Servizi } from './../../model/model-mypa-volontariato';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { faArrowLeft, faBullseye, faFlag, faFolder, faPlus, faSortAmountUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-my-pa-ricerca-posizione-aperta',
  templateUrl: './my-pa-ricerca-posizione-aperta.component.html',
  styleUrls: ['./my-pa-ricerca-posizione-aperta.component.scss']
})
export class MyPaRicercaPosizioneApertaComponent implements OnInit {

  public iconArrowLeft: any = faArrowLeft;
  public iconSortAmountUp: any = faSortAmountUp;
  public iconPlus: any = faPlus;
  public iconFolder: any = faFolder;
  public iconFlag: any = faFlag;
  public iconBullseye: any = faBullseye;

  public showEnteForm: boolean = true;
  public showDenominazioneForm: boolean = false;
  public showStrutturaComunaleForm: boolean = false;
  public showSettoreForm: boolean = false;
  public showAttivitaForm: boolean = false;
  public showDestinatariForm: boolean = false;

  public form: FormGroup;

  public page: number = 0;
  public size: number = 5;

  public listaComune: Territorio[] = [];
  public listaTipoStruttura: TipoStruttura[] = [];
  public listaSettore: Settore[] = [];
  public listaAttivita: Attivita[] = [];
  public listaTargetPF: Target[] = [];
  public listaTargetPNF: Target[] = [];
  public listaPosizioni: Posizione[] = [];
  public totalElements: number = 0;

  public ente: string;

  public inRicerca: boolean = false;
  public erroreRicerca: boolean = false;
  public primaRicerca: boolean = true;

  constructor(protected formBuilder: FormBuilder,
    private myPaVolontariatoService: MypaVolontariatoService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.ente = this.activatedRoute.snapshot.paramMap.get("ente");
    this.findTerritori();
    this.findTipoStrutture();
    this.findSettori();
    this.findAttivita();
    this.findTarget();
    this.buildForm();
    this.onSubmit();
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      ente: [this.ente ? this.ente : null, null],
      denominazione: [null, null],
      strutturaComunale: [null, null],
      settore: [null, null],
      attivita: [null, null],
      destinatari: [null, null],
    });
  }

  public cerca(): void {
    this.page = 0;
    this.size = 5;
    this.primaRicerca = false;
    this.onSubmit();
  }

  public onSubmit(): void {
    this.inRicerca = true;
    this.erroreRicerca = false;
    let posizioneInfoFilter: PosizioneInfoFilter = this.mapFormToPosizioneInfoFilter();
    this.myPaVolontariatoService.listaPosizioniAperte(posizioneInfoFilter, this.page, this.size).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          this.inRicerca = false;
          response.entity.content.forEach(
            posizione => {
              /* prelevamento campi da specifici punti del dto --> */
              posizione.comune = posizione.denominazione_ente;
              posizione.nomePosizione = posizione.profiloObj.id == 8 ? posizione.descrizione_profilo : posizione.profiloObj.descrizione;
              /* <-- */

              /* popolamento target --> */
              let listaTarget: Target[] = posizione.servizioObj.target;
              posizione.listaTarget = [];
              listaTarget.forEach(
                target => {
                  if (target.id == 38 || target.id == 50) {
                    posizione.listaTarget.push(target.descrizione_altro);
                  }
                  else {
                    posizione.listaTarget.push(target.descrizione);
                  }
                }
              );
              /* <-- */
            }
          );
          this.listaPosizioni = response.entity.content;
          this.totalElements = response.entity.totalElements;
        }
      },
      error => {
        this.inRicerca = false;
        this.erroreRicerca = true;
        this.listaPosizioni = [];
      }
    );
  }

  private mapFormToPosizioneInfoFilter(): PosizioneInfoFilter {
    let posizioneInfoFilter: PosizioneInfoFilter = new PosizioneInfoFilter();
    posizioneInfoFilter.prima_ricerca = this.primaRicerca;
    if (this.form.get("ente").value.length == 2) {
      posizioneInfoFilter.area_urbana = this.form.get("ente").value;
    }
    else {
      posizioneInfoFilter.ente = this.form.get("ente").value;
    }
    if (this.form.get("denominazione").value && this.form.get("denominazione").value != "") {
      posizioneInfoFilter.denominazione = this.form.get("denominazione").value;
    }
    if (this.form.get("strutturaComunale").value) {
      posizioneInfoFilter.tipo_struttura = this.form.get("strutturaComunale").value;
    }
    if (this.form.get("settore").value) {
      posizioneInfoFilter.settore = this.form.get("settore").value;
    }
    if (this.form.get("attivita").value) {
      posizioneInfoFilter.attivita = this.form.get("attivita").value;
    }
    if (this.form.get("destinatari").value) {
      posizioneInfoFilter.target = this.form.get("destinatari").value;
    }
    return posizioneInfoFilter;
  }

  public candidati(posizione: Posizione): void {
    window.open(`${posizione.urlCandidatura}${posizione.ente}/${posizione.userRegistryId}?id=${posizione.id}`, '_blank');
    /* window.location.href = `${posizione.urlCandidatura}${posizione.ente}/${posizione.userRegistryId}?id=${posizione.id}`; */
  }

  public paginazione(event: any): void {
    this.page = event.pageIndex;
    this.size = event.pageSize;
    this.onSubmit();
  }

  public indietro(): void {
    window.open("https://google.com", '_blank');
    /* window.location.href = "https://google.com"; */
  }

  public findTerritori(): void {
    this.myPaVolontariatoService.findTerritori(this.ente).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          /* capisco quante sono le aree urbane */
          let setCodiciAU: Set<string> = new Set<string>();
          response.entity.forEach(elem => {
            setCodiciAU.add(elem.codice_area_urbana);
          });
          /* aggiunto in cima ad ogni gruppo l'area urbana di riferimento come ulteriore parametro di ricerca */
          setCodiciAU.forEach(codice => {
            let comuniAU: Territorio[] = response.entity.filter(elem => elem.codice_area_urbana == codice);
            let denominazioneAreaUrbana: string = comuniAU[0].denominazione_area_urbana;
            let areaUrbana: Territorio = new Territorio();
            /* areaUrbana.denominazione = "Area Urbana " + denominazioneAreaUrbana;
            areaUrbana.ente = codice;
            comuniAU.unshift(areaUrbana); */
            this.listaComune = this.listaComune.concat(comuniAU);
          });
        }
      }
    );
  }

  public findTipoStrutture(): void {
    this.myPaVolontariatoService.findTipoStrutture(this.ente).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          this.listaTipoStruttura = response.entity;
        }
      }
    );
  }

  public findSettori(): void {
    this.myPaVolontariatoService.findSettori(this.ente).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          this.listaSettore = response.entity;
        }
      }
    );
  }

  public findAttivita(): void {
    this.myPaVolontariatoService.findAttivita(this.ente).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          this.listaAttivita = response.entity;
        }
      }
    );
  }

  public findTarget(): void {
    this.myPaVolontariatoService.findTarget(this.ente).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          this.listaTargetPF = response.entity.filter(target => target.tipo == "PF");
          this.listaTargetPNF = response.entity.filter(target => target.tipo == "PNF");
        }
      }
    );
  }

}
