<header role="banner" aria-label="Barra di navigazione">
  <mat-toolbar role="navigation" color="primary" aria-label="Toolbar principale">

    <mat-toolbar-row>
      <button style="margin-right: 1%;" mat-icon-button aria-label="Apri Menù" (click)="toggleSidenav()"
        focusvisible="true">
        <fa-icon [icon]="iconBars" size="2x" class="active" alt="Menu"></fa-icon>
      </button>
      <a style="margin-right: 1%;" fxShow="false" fxShow.gt-xs href="https://mypa.regione.veneto.it/mypa/a"><img
          class="logo" src="assets/images/logo-mypa-neg.svg" alt="MyPa Home" height="33"></a>
      <a fxShow="false" fxShow.gt-sm href="https://www.regione.veneto.it/"><img class="logo"
          src="assets/images/logo-regione-veneto--bw.png" alt="Logo regione Veneto"></a>
      <span class="flex-spacer" aria-hidden="true"></span>
      <button mat-button *ngFor="let item of menuService.getMenuFirstLevel(logged,'center')"
        [fxShow]="item.active || item.dontHide" [class]="item.active?'active-first':null" fxShow.gt-md
        (click)="menuService.onClickFirstLevel(item)">
        <span class="side-user-icon">
          <img *ngIf="item.hasImgIcon()" [src]="item.icon" class="side-user-icon-img" />
          <fa-icon *ngIf="item.hasFAIcon()" [icon]="item.icon" size="lg"></fa-icon>
        </span>
        <span>{{item.getHeaderLabel()}}</span>
      </button>
      <span class="flex-spacer" aria-hidden="true"></span>

      <button mat-button *ngIf="isDettaglioPosizioneConsenso" (click)="toBacheca()">
        <span fxShow="false" fxShow.gt-sm class="login-text">Bacheca</span>
      </button>

      <button mat-button *ngIf="isDettaglioPosizioneConsenso" (click)="toScadenze()">
        <span fxShow="false" fxShow.gt-sm class="login-text">Scadenze</span>
      </button>

      <button mat-button *ngIf="isDettaglioPosizioneConsenso" (click)="toDomande()">
        <span fxShow="false" fxShow.gt-sm class="login-text">Domande</span>
      </button>

      <button mat-button *ngIf="isDettaglioPosizioneConsenso" (click)="toPagamenti()">
        <span fxShow="false" fxShow.gt-sm class="login-text">Pagamenti</span>
      </button>

      <button mat-button *ngIf="isDettaglioPosizioneConsenso" (click)="toAvvisi()">
        <span fxShow="false" fxShow.gt-sm class="login-text">Avvisi</span>
      </button>

      <button style="margin-right: 7%;" mat-button *ngIf="isDettaglioPosizioneConsenso" (click)="toPreferiti()">
        <span fxShow="false" fxShow.gt-sm class="login-text">Preferiti</span>
      </button>

      <button mat-button *ngIf="isDettaglioPosizioneConsenso" (click)="toProfilo()">
        <img style="margin-right: 5%;" alt="Immagine del profilo" class="header-avatar" src="assets/images/avatar.jpg">
        <span fxShow="false" fxShow.gt-sm class="login-text">Profilo e impostazioni</span>
      </button>

      <button style="margin-right: 15%;" mat-button *ngIf="!isDettaglioPosizioneConsenso" (click)="trovaConMarco()">
        <!-- <fa-icon fxHide="false" fxHide.gt-sm [icon]="iconSignIn" size="lg" matTooltip="Trova il servizio con Marco"></fa-icon> -->
        <img style="width: 30px; height: 30px;"
          src="https://mypa.regione.veneto.it/mypa/client/application/assets/images/marco.png"
          alt="Trova il servizio con Marco">
        <span fxShow="false" fxShow.gt-sm class="login-text">Trova il servizio con Marco</span>
      </button>

      <button mat-button *ngIf="!isDettaglioPosizioneConsenso" [matMenuTriggerFor]="menu">
        <!-- (click)="openLoginForm()" -->
        <fa-icon fxHide="false" fxHide.gt-sm [icon]="iconSignIn" size="lg"
          matTooltip="Accedi all'Area Personale del cittadino"></fa-icon>
        <span fxShow="false" fxShow.gt-sm class="login-text">Accedi all'Area Personale del cittadino</span>
      </button>
      <mat-menu #menu="matMenu">
        <div style="margin-bottom: 5%;">
          <span style="font-weight: bold;">Usa il tuo account SPID o MyID</span>
          <div style="margin-bottom: 5%;">
            Vai alla pagina di autenticazione di MyID, il sistema federato di identificazione di Regione del Veneto, o
            utilizza SPID.
          </div>
          <button style="text-align: center; font-weight: bold; color: white; background-color: #0066cc;" mat-menu-item
            (click)="accountSPID()">
            Usa il tuo account SPID o MyID
          </button>
        </div>
        <hr>
        <div style="margin-top: 5%;">
          <span style="font-weight: bold;">Non hai ancora SPID o MyID?</span>
          <div style="margin-bottom: 5%;">
            Accedi con i social ma alcune funzionalità non saranno attive
          </div>
          <button
            style="text-align: center; margin-bottom: 5%; font-weight: bold; color: white; background-color: #16343e;"
            mat-menu-item (click)="accountSocial()">
            Accedi con un account social
          </button>
        </div>
        <hr>
        <button style="font-weight: bold; color: #004B5C;" mat-menu-item (click)="differenzeSPIDSocial()">
          Differenze tra SPID e social?
        </button>
      </mat-menu>

      <button mat-button *ngFor="let item of menuService.getMenuFirstLevel(logged,'right')"
        [fxShow]="item.active || item.dontHide" fxShow.gt-md [class]="item.active?'active-first':null"
        (click)="menuService.onClickFirstLevel(item)" aria-labelledby="{{item.getId()}}">
        <span class="side-user-icon">
          <img *ngIf="item.hasImgIcon()" [src]="item.icon" class="side-user-icon-img" />
          <fa-icon *ngIf="item.hasFAIcon()" [icon]="item.icon" size="lg" alt="{{item}}"></fa-icon>
        </span>
        <span id="{{item.getId()}}" class="sr-only">{{item.getAriaLabel()}}</span>
        <span aria-hidden="true">{{item.getHeaderLabel()}}</span>
      </button>

      <button *ngIf="logged == true" mat-button aria-label="Menu Notifiche" [matMenuTriggerFor]="menuNotification"
        (click)="onClickNotification()" [matMenuTriggerData]="notifications">
        <span class="side-user-icon" matTooltip="Notifiche">
          <fa-icon [icon]="iconBell" size="lg" alt=item></fa-icon>
        </span>
      </button>
      <mat-menu #menuNotification="matMenu">
        <ng-template matMenuContent="notifications">
          <button mat-menu-item *ngIf="!notifications || notifications.length==0">Nessuna notifica</button>
          <ng-container *ngFor="let notification of notifications">
            <button mat-menu-item>{{notification}}</button>
          </ng-container>
        </ng-template>
      </mat-menu>

      <button mat-button aria-label="Esci" matTooltip="Esci" *ngIf="logged" (click)="logout()">
        <fa-icon [icon]="itemSignOut" size="lg" alt="Esci"></fa-icon>
      </button>

    </mat-toolbar-row>
  </mat-toolbar>
  <mat-toolbar [class]="classHeader" *ngIf="secondLevelMenu?.length>0" color="accent" class="second-level mypay-toolbar"
    role="heading" aria-level="2" aria-label="">
    <mat-toolbar-row>
      <span class="flex-spacer" aria-hidden="true"></span>
      <button mat-button *ngFor="let item of secondLevelMenu" class="mypay-toolbar-item"
        [href]="item.external?item.url:null" [routerLink]="item.external?null:item.url"
        (click)="myPayBreadcrumbsService.resetBreadcrumbs()" routerLinkActive="active-second" aria-label="{{item}}">
        <span class="side-user-icon">
          <img *ngIf="item.hasImgIcon()" [src]="item.icon" class="side-user-icon-img" />
          <fa-icon *ngIf="item.hasFAIcon() && !item.hasFABadge()" [icon]="item.icon" size="lg"></fa-icon>
          <fa-layers class="badge-fa-layers" *ngIf="item.hasFAIcon() && item.hasFABadge()" [fixedWidth]="true">
            <fa-icon [icon]="item.icon" size="lg"></fa-icon>
            <fa-layers-counter [content]="item.iconBadgeFun()"></fa-layers-counter>
          </fa-layers>
        </span>
        <span>{{item.label}}</span>
      </button>
      <button mat-icon-button aria-label="Apri toolbar" class="toolbar-trigger" (click)="onClickButtonHeader()">
        <fa-icon [icon]="iconHeader" size="lg"></fa-icon>
      </button>
      <span class="flex-spacer" aria-hidden="true"></span>
    </mat-toolbar-row>

  </mat-toolbar>
</header>