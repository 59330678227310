import { DateTime } from 'luxon';

import { WithActions } from '../table/with-actions';

export class User extends WithActions{
  userId: number;
  username: string;
  nome: string;
  cognome: string;
  codiceFiscale: string;
  email: string;
  telefono: string;
  indirizzo: string;
  civico: string;
  cap: string;
  comuneId: number;
  provinciaId: number;
  nazioneId: number;
  comune: string;
  provincia: string;
  nazione: string;
  entiRoles: { [ente: string]: string[] };
  lastLogin: DateTime;

  rememberMe: boolean;
}
