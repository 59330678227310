import { Struttura } from './../../model/model-mypa-volontariato';
import { ActivatedRoute } from '@angular/router';
import { MypaVolontariatoService } from './../../services/mypa-volontariato/mypa-volontariato.service';
import { Component, OnInit } from '@angular/core';
import { faBullseye, faCalendarDay, faClock, faFlag, faFolder, faLocationArrow, faMapMarked, faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dettaglio-associazione',
  templateUrl: './dettaglio-associazione.component.html',
  styleUrls: ['./dettaglio-associazione.component.scss']
})
export class DettaglioAssociazioneComponent implements OnInit {

  public iconLocationArrow: any = faLocationArrow;
  public iconUser: any = faUser;
  public iconBullseye: any = faBullseye;
  public iconMapMarked: any = faMapMarked;
  public iconFolder: any = faFolder;
  public iconCalendarDay: any = faCalendarDay;
  public iconFlag: any = faFlag;
  public iconClock: any = faClock;

  public id: number;
  public ente: string;
  public struttura: Struttura = new Struttura();

  constructor(private myPaVolontariatoService: MypaVolontariatoService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = Number(this.activatedRoute.snapshot.paramMap.get("id"));
    this.ente = localStorage.getItem("ente");
    this.dettaglioStrutturaById();
  }

  public dettaglioStrutturaById(): void {
    this.myPaVolontariatoService.dettaglioStrutturaById(this.id, this.ente).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          /* popolamento del campo doveOpera con le province presenti nel campo province_territorio --> */
          response.entity.doveOpera = [];
          JSON.parse(response.entity.province_territorio).province.forEach(
            provincia => {
              response.entity.doveOpera.push(provincia);
            }
          );
          /* <-- */

          /* popolamento del campo giorniDellaSettimana con i valori del campo giorni_attivita di ogni servizio --> */
          response.entity.giorniDellaSettimana = new Set<string>();
          response.entity.servizi.forEach(
            servizio => {
              JSON.parse(servizio.giorni_attivita).giorni_attivita.forEach(
                giorno => {
                  response.entity.giorniDellaSettimana.add(giorno);
                }
              );
            }
          );
            /* ordinamento giorni settimana --> */
            let listaGiorni: string[] = [...response.entity.giorniDellaSettimana];
            response.entity.giorniDellaSettimana = this.ordinaGiorniSettimana(listaGiorni);
            /* <-- */
          /* <-- */

          /* popolamento del campo attivita con i valori del campo attivita di ogni servizio --> */
          response.entity.attivita = new Set<string>();
          response.entity.servizi.forEach(
            servizio => {
              servizio.attivita.forEach(
                attivita => {
                  if (attivita.id == 26) {
                    response.entity.attivita.add(attivita.descrizione_altro);
                  }
                  else {
                    response.entity.attivita.add(attivita.descrizione);
                  }
                }
              );
            }
          );
          /* <-- */

          /* popolamento del campo orariAttivita con i valori del campo ore_attivita di ogni servizio --> */
          response.entity.orariAttivita = new Set<string>();
          response.entity.servizi.forEach(
            servizio => {
              JSON.parse(servizio.ore_attivita).ore_attivita.forEach(
                ora => {
                  response.entity.orariAttivita.add(ora);
                }
              );
            }
          );
            /* ordinamento crescente --> */
            let listaOrari: string[] = [...response.entity.orariAttivita];
            listaOrari.sort((a, b) => a.localeCompare(b));
            /* <-- */
          response.entity.orariAttivita = new Set<string>(listaOrari);
          /* <-- */

          /* popolamento del campo target con i valori del campo target di ogni servizio --> */
          response.entity.target = new Set<string>();
          response.entity.servizi.forEach(
            servizio => {
              servizio.target.forEach(
                target => {
                  if (target.id == 38 || target.id == 50) {
                    response.entity.target.add(target.descrizione_altro);
                  }
                  else {
                    response.entity.target.add(target.descrizione);
                  }
                }
              );
            }
          );
          /* <-- */

          if (response.entity.tipoStruttura.id == 1) {
            response.entity.sede_leg_citta = JSON.parse(response.entity.province_territorio).province[0];
          }

          this.struttura = response.entity;
        }
      }
    );
  }

  private ordinaGiorniSettimana(listaGiorni: string[]): Set<string> {
    let setGiorni: Set<string> = new Set<string>();
    if (listaGiorni.includes("Lunedì")) {
      setGiorni.add(listaGiorni.find(giorno => giorno == "Lunedì"));
    }
    if (listaGiorni.includes("Martedì")) {
      setGiorni.add(listaGiorni.find(giorno => giorno == "Martedì"));
    }
    if (listaGiorni.includes("Mercoledì")) {
      setGiorni.add(listaGiorni.find(giorno => giorno == "Mercoledì"));
    }
    if (listaGiorni.includes("Giovedì")) {
      setGiorni.add(listaGiorni.find(giorno => giorno == "Giovedì"));
    }
    if (listaGiorni.includes("Venerdì")) {
      setGiorni.add(listaGiorni.find(giorno => giorno == "Venerdì"));
    }
    if (listaGiorni.includes("Sabato")) {
      setGiorni.add(listaGiorni.find(giorno => giorno == "Sabato"));
    }
    if (listaGiorni.includes("Domenica")) {
      setGiorni.add(listaGiorni.find(giorno => giorno == "Domenica"));
    }
    return setGiorni;
  }

}
