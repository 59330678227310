import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { DynamicPipe } from '../../pipes/dynamic-pipe';
import { TableColumn } from '../../table/table-column';

@Component({
  selector: 'lib-my-pay-table-detail',
  templateUrl: './my-pay-table-detail.component.html',
  styleUrls: ['./my-pay-table-detail.component.scss']
})
export class MyPayTableDetailComponent implements OnInit {

  public details: any;
  public element: any;
  public tableId: string;
  public parentRef: any;
  public actionColumn: TableColumn;
  public detailFilterInclude: string[];
  public detailFilterExclude: string[];

  iconTimes = faTimes;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dynamicPipe: DynamicPipe,
  ) {
    this.element = data.element;
    this.tableId = data.tableId;
    this.parentRef = data.parentRef;
    this.detailFilterInclude = data.detailFilterInclude;
    this.detailFilterExclude = data.detailFilterExclude;

    const details = [];
    data.tableColumnns.forEach((aColumn:TableColumn) => {
      if(!aColumn.actions && !aColumn.hasIcon && aColumn.id!=="tableExpandColumn")
        details.push({key:aColumn.label, value: this.dynamicPipe.transform(this.element[aColumn.id], aColumn.pipe, aColumn.pipeArgs)})
      if(aColumn.actions)
        this.actionColumn = aColumn;
    });
    this.element.details?.forEach(element => {
      if(!details.find(elem => elem.key === element.key))
        details.push(element);
    });
    this.details = details;
  }

  ngOnInit(): void {
  }

}
