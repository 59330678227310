import { environment } from './../../../../../mypa-fe-common/src/lib/environments/environment';
import { ApiInvokerService } from './../../../../../mypa-fe-common/src/lib/services/api-invoker.service';
import { Result, Struttura, Page, PosizioneInfoFilter, Posizione, Territorio, TipoStruttura, Settore, Attivita, Target, Consenso } from './../../model/model-mypa-volontariato';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MypaVolontariatoService {

  private baseUrl: string = `${environment.baseApiUrl}/mypa/a`;

  constructor(private http: HttpClient,
    private apiInvokerservice: ApiInvokerService) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  public listaStrutture(ente: string): Observable<Result<Struttura[]>> {
    const url = `${this.baseUrl}/portale-informativo/listaStrutture`;
    let params: HttpParams = new HttpParams();
    params = params.append("ente", ente);
    return this.http.get<Result<Struttura[]>>(url, { params: params })
      .pipe(
        tap(_ => console.log('get lista strutture')),
        catchError(this.handleError<Result<Struttura[]>>('errore get lista strutture', new Result<Struttura[]>()))
      );
  }

  public dettaglioStrutturaById(id: number, ente: string): Observable<Result<Struttura>> {
    const url = `${this.baseUrl}/portale-informativo/dettaglioStrutturaById`;
    let params: HttpParams = new HttpParams();
    params = params.append("id", id.toString());
    params = params.append("ente", ente);
    return this.http.get<Result<Struttura>>(url, { params: params })
      .pipe(
        tap(_ => console.log(`get dettaglio struttura con id: ${id}`)),
        catchError(this.handleError<Result<Struttura>>(`errore get dettaglio struttura con id: ${id}`, new Result<Struttura>()))
      );
  }

  public listaPosizioniAperte(posizioneInfoFilter: PosizioneInfoFilter, page: number, size: number): Observable<Result<Page<Posizione>>> {
    const url = `${this.baseUrl}/portale-informativo/listaPosizioniAperte`;
    let params: HttpParams = new HttpParams();
    params = params.append("page", page.toString());
    params = params.append("size", size.toString());
    return this.http.post<Result<Page<Posizione>>>(url, posizioneInfoFilter, { params: params })
      .pipe(
        tap(_ => console.log(`get lista posizioni aperte`)),
        /* catchError(this.handleError<Result<Page<Posizione>>>(`errore get lista posizioni aperte`, new Result<Page<Posizione>>())) */
      );
  }

  public dettaglioPosizioneById(id: number, ente: string): Observable<Result<Posizione>> {
    const url = `${this.baseUrl}/api/search/volontariato/dettaglioPosizioneById`;
    let params: HttpParams = new HttpParams();
    params = params.append("id", id.toString());
    params = params.append("ente", ente);
    return this.http.get<Result<Posizione>>(url, { params: params })
      .pipe(
        tap(_ => console.log(`get dettaglio posizione con id: ${id}`)),
        catchError(this.handleError<Result<Posizione>>(`errore get dettaglio posizione con id: ${id}`, new Result<Posizione>()))
      );
  }

  public getConfiguration(): Observable<any> {
    const url = `${this.baseUrl}/portale-informativo/getConfigurationParameters`;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => console.log(`get parametri di configurazione`)),
        catchError(this.handleError<any>(`errore get parametri di configurazione`, []))
      );
  }

  public findTerritori(ente: string): Observable<Result<Territorio[]>> {
    const url = `${this.baseUrl}/api/tipologiche/territori`;
    let params: HttpParams = new HttpParams();
    params = params.append("ente", ente);
    return this.http.get<Result<Territorio[]>>(url, { params: params })
      .pipe(
        tap(_ => console.log(`get lista territori`)),
        catchError(this.handleError<Result<Territorio[]>>(`errore get lista territori`, new Result<Territorio[]>()))
      );
  }

  public findTipoStrutture(ente: string): Observable<Result<TipoStruttura[]>> {
    const url = `${this.baseUrl}/api/tipologiche/tipoStrutture`;
    let params: HttpParams = new HttpParams();
    params = params.append("ente", ente);
    return this.http.get<Result<TipoStruttura[]>>(url, { params: params })
      .pipe(
        tap(_ => console.log(`get lista tipo strutture`)),
        catchError(this.handleError<Result<TipoStruttura[]>>(`errore get lista tipo strutture`, new Result<TipoStruttura[]>()))
      );
  }

  public findSettori(ente: string): Observable<Result<Settore[]>> {
    const url = `${this.baseUrl}/api/tipologiche/settori`;
    let params: HttpParams = new HttpParams();
    params = params.append("ente", ente);
    return this.http.get<Result<Settore[]>>(url, { params: params })
      .pipe(
        tap(_ => console.log(`get lista tipo settori`)),
        catchError(this.handleError<Result<Settore[]>>(`errore get lista tipo settori`, new Result<Settore[]>()))
      );
  }

  public findAttivita(ente: string): Observable<Result<Attivita[]>> {
    const url = `${this.baseUrl}/api/tipologiche/attivita`;
    let params: HttpParams = new HttpParams();
    params = params.append("ente", ente);
    return this.http.get<Result<Attivita[]>>(url, { params: params })
      .pipe(
        tap(_ => console.log(`get lista tipo attivita`)),
        catchError(this.handleError<Result<Attivita[]>>(`errore get lista tipo attivita`, new Result<Attivita[]>()))
      );
  }

  public findTarget(ente: string): Observable<Result<Target[]>> {
    const url = `${this.baseUrl}/api/tipologiche/target`;
    let params: HttpParams = new HttpParams();
    params = params.append("ente", ente);
    return this.http.get<Result<Target[]>>(url, { params: params })
      .pipe(
        tap(_ => console.log(`get lista tipo target`)),
        catchError(this.handleError<Result<Target[]>>(`errore get lista tipo target`, new Result<Target[]>()))
      );
  }

  public isLogged(): Observable<any> {
    const url = `${this.baseUrl}/api/utente/is-logged`;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => console.log(`verifica se utente loggato`)),
        catchError(this.handleError<any>(`errore durante verifica se utente loggato`, false))
      );
  }

  public getInfoConsenso(idAssociazione: number, ente: string): Observable<Result<Consenso>> {
    const url = `${this.baseUrl}/api/search/volontariato/getInfoConsenso`;
    let params: HttpParams = new HttpParams();
    params = params.append("struttura", idAssociazione.toString());
    params = params.append("ente", ente);
    return this.http.get<Result<Consenso>>(url, { params: params })
      .pipe(
        tap(_ => console.log(`get dettaglio consenso per associazione con id: ${idAssociazione}`)),
        catchError(this.handleError<Result<Consenso>>(`errore get dettaglio consenso per associazione con id: ${idAssociazione}`, new Result<Consenso>()))
      );
  }

  public richiestaConsenso(idAssociazione: number, consenso: boolean, ente: string): Observable<Result<boolean>> {
    const url = `${this.baseUrl}/api/search/volontariato/richiestaConsenso`;
    let params: HttpParams = new HttpParams();
    params = params.append("struttura", idAssociazione.toString());
    params = params.append("consenso", consenso.toString());
    params = params.append("ente", ente);
    return this.http.get<Result<boolean>>(url, { params: params })
      .pipe(
        tap(_ => console.log(`richiesta consenso per associazione con id: ${idAssociazione}`)),
        catchError(this.handleError<Result<boolean>>(`errore richiesta consenso per associazione con id: ${idAssociazione}`, new Result<boolean>()))
      );
  }

}
