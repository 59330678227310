import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import { TableAction } from './table-action';
import { WithActions } from './with-actions';

export type ColumnType = 'text' | 'img64';

export class TableColumn {
  id: string;
  type: ColumnType;
  label: string | IconDefinition;
  sortable: boolean | ((item:any)=>string|number);
  pipe: any;
  pipeArgs: any[];
  tooltip: string;
  actions: TableAction[];
  ariaLabel: string;
  totalLabel: boolean = false;
  dispCondition : () => boolean;
  cellClick: (tableId: string, parentRef: any, element:any, tableColumn: TableColumn, onlyCheckEnabled?: boolean) => void | boolean;

  //calculated fields
  hasIcon: boolean;

  constructor(id:string, label:string | IconDefinition, options: {
      type?: ColumnType,
      sortable?: boolean | ((item:any)=>string|number),
      pipe?: any,
      pipeArgs?: any[],
      tooltip?: string,
      actions?: TableAction[],
      ariaLabel?: string,
      totalLabel?: boolean,
      dispCondition?: ()=>boolean,
      cellClick?: (tableId: string, parentRef: any, element:any, tableColumn: TableColumn, onlyCheckEnabled?: boolean) => void | boolean} = null) {
    options = options || {};

    this.id = id;
    this.label = label;
    this.type = options.type || 'text';
    this.sortable = (options.type !== 'img64') && (options.sortable==null || options.sortable);
    this.pipe = options.pipe;
    this.pipeArgs = options.pipeArgs;
    this.tooltip = options.tooltip;
    this.actions = options.actions;
    this.ariaLabel = options.ariaLabel || label.toString();
    this.totalLabel = options.totalLabel;
    this.dispCondition = options.dispCondition;
    this.cellClick = options.cellClick;

    this.hasIcon = label && typeof label !== 'string';
  }

  enabledActions(tableId: string, thisRef: any, elementRef: WithActions):TableAction[] {
    if(!(elementRef.enabledActions instanceof Map))
      elementRef.enabledActions = new Map();
    if(elementRef.enabledActions.has(tableId))
      return elementRef.enabledActions.get(tableId);

    const filtered = this.actions?.filter(action => {
      return !action.clickEnabledFun || action.clickEnabledFun(elementRef, thisRef);
    });
    elementRef.enabledActions.set(tableId, filtered);

    return filtered;
  }


}
