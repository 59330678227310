import {
    ApiInvokerService, BaseUrlService, mapper
} from 'projects/mypa-fe-common/src/public-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Debito } from '../model/debito';
import { Pagato } from '../model/pagato';

@Injectable({
  providedIn: 'root'
})
export class AvvisoService {

  constructor(
    private baseUrlService: BaseUrlService,
    private apiInvokerService: ApiInvokerService
  ) { }

  searchAvvisi(numeroAvviso: string, owner: boolean, codIdUnivoco: string): Observable<any> {
    return this.searchAvvisiImpl(false, numeroAvviso, owner, codIdUnivoco, null);
  }

  searchAvvisiAnonymous(numeroAvviso: string, codIdUnivoco: string, recaptchaResponse: any): Observable<any> {
    return this.searchAvvisiImpl(true, numeroAvviso, null, codIdUnivoco, recaptchaResponse);
  }

  private searchAvvisiImpl(anonymous:boolean, numeroAvviso: string, owner: boolean, codIdUnivoco: string, recaptchaResponse: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('numeroAvviso', numeroAvviso);
    if(anonymous){
      params = params
      .append('codIdUnivoco', codIdUnivoco)
      .append('recaptcha', recaptchaResponse);
    } else {
      params = params.append('owner', String(owner));
      if(!owner)
        params = params.append('codIdUnivoco', codIdUnivoco);
    }

    return this.apiInvokerService.get<any>(this.baseUrlService.getCittadinoUrl(anonymous) + 'avvisi/search', {params:params})
      .pipe(map(response => {
        response.debiti = response.debiti?.map(item => mapper(item, Debito.MAPPER_S2C_DEF));
        response.pagati = response.pagati?.map(item => mapper(item, Pagato.MAPPER_S2C_DEF));
        return response;
      }));
  }

  downloadAvviso(debito: Debito): any {
    return this.downloadAvvisoImpl(false, debito);
  }

  downloadAvvisoAnonymous(debito: Debito, recaptchaResponse: any): any {
    return this.downloadAvvisoImpl(true, debito, recaptchaResponse);
  }

  private downloadAvvisoImpl(anonymous:boolean, debito: Debito, recaptchaResponse?: any): any {
    let params = new HttpParams();
    if(anonymous)
      params = params.append('recaptcha', recaptchaResponse);
    if(debito.securityTokenAvviso)
      params = params.append('securityToken', debito.securityTokenAvviso);
    return this.apiInvokerService.get<any>(this.baseUrlService.getCittadinoUrl(anonymous) + 'avvisi/download/' + debito.id, {params:params, responseType: 'blob'});
  }

}
