<form *ngIf="form" (ngSubmit)="addToCarrello()" [formGroup]="form">
  <div *ngIf="contentTypeSchemaItems" class="container mypay-search-form"
    fxFlex fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">

    <ng-container *ngFor="let item of contentTypeSchemaItems | filterRenderableItems | orderby:'insertableOrder'">
        <div *ngIf="!(item.maxOccurences < 0)">
            <content-editing-property [schemaItem]="item" [key]="item.name" [form]="form" [labelClass]="labelClass" [inputClass]="inputClass" [readOnly]="addedToCart"></content-editing-property>
        </div>
        <div *ngIf="(item.maxOccurences < 0)">
            <div [formArrayName]="item.name">
                <div *ngFor="let control of form.controls[item.name].controls; let i = index" class="container">
                    <div [formGroupName]="i" class="row">
                        <div class="col-xs-10">
                            <content-editing-property [schemaItem]="item" [key]="i.toString()" [form]="form.get(item.name)" [labelClass]="labelClass" [inputClass]="inputClass" [readOnly]="addedToCart"></content-editing-property>
                        </div>
                        <div *ngIf="i > 0" class="col-xs-1">
                            <button class="btn btn-danger" type="button" (click)="removeFieldButtonClicked(item, i)"><i class="fa fa-minus-circle"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-secondary" type="button" (click)="addFieldButtonClicked(item)"><i class="fa fa-plus-circle"></i></button>
        </div>
    </ng-container>

    <mat-label id="sr-imp" class="sr-only">Importo</mat-label>
    <mat-form-field *ngIf="!totalIncluded" fxFlex appearance="{{'appearance'|global}}" [floatLabel]="'always'">
      <mat-label aria-hidden="true">Importo</mat-label>
      <input aria-labelledby="sr-imp" matInput type="text" required [readonly]="importoPrefissato != null || addedToCart"
          formControlName="totalImporto" (change)="thisformOnChange('totalImporto')">
      <mat-error *ngIf="thisformFields.totalImporto?.length > 0">{{ thisformFields.totalImporto }}</mat-error>
    </mat-form-field>

    <h3 fxFlex class="mat-h3 text-center">Dati intestatario</h3>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
      <mat-label id="sr-ana" class="sr-only">Anagrafica</mat-label>
      <mat-form-field fxFlex="100%" fxFlex.gt-sm="35%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
        <mat-label aria-hidden="true">Anagrafica</mat-label>
        <input aria-labelledby="sr-ana" matInput required formControlName="sys_intestatario_anagrafica">
        <mat-error *ngIf="formErrors['sys_intestatario_anagrafica']">{{ formErrors.sys_intestatario_anagrafica }}</mat-error>
      </mat-form-field>
      <div fxFlex="100%" fxFlex.gt-sm="10em" fxLayout="column" class="tipo-persona-group">
        <span fxFlex class="mat-caption">Tipo persona</span>
        <div fxFlex>
        <mat-button-toggle-group (change)="onChangeTipoPersona($event)" formControlName="sys_intestatario_tipoSoggetto">
          <mat-button-toggle value="F">Fisica</mat-button-toggle>
          <mat-button-toggle value="G">Giuridica</mat-button-toggle>
        </mat-button-toggle-group></div>
      </div>
      <mat-label id="sr-univoco" class="sr-only">{{codiceIdentificativoUnivocoLabel}}</mat-label>
      <mat-form-field fxFlex="100%" fxFlex.gt-sm="18%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
        <mat-label aria-hidden="true">{{codiceIdentificativoUnivocoLabel}}</mat-label>
        <input aria-labelledby="sr-univoco" matInput required formControlName="sys_intestatario_codiceIdentificativoUnivoco">
        <mat-error *ngIf="formErrors['sys_intestatario_codiceIdentificativoUnivoco']">{{ formErrors.sys_intestatario_codiceIdentificativoUnivoco }}</mat-error>
      </mat-form-field>
      <mat-label id="sr-email" class="sr-only">Email</mat-label>
      <mat-form-field fxFlex="100%" fxFlex.gt-sm appearance="{{'appearance'|global}}" [floatLabel]="'always'">
        <mat-label aria-hidden="true">Email</mat-label>
        <input aria-labelledby="sr-email" matInput required formControlName="sys_intestatario_email">
        <mat-error *ngIf="formErrors['sys_intestatario_email']">{{ formErrors.sys_intestatario_email }}</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
      <button type="button" mat-flat-button color="primary" aria-label="Annulla" (click)="onReload()">Annulla</button>
      <button type="submit" *ngIf="!addedToCart" mat-flat-button [disabled]="form.invalid" color="accent" aria-label="Aggiungi al carrello">Aggiungi al carrello</button>
      <button type="button" *ngIf="addedToCart" mat-flat-button [routerLink]="'/carrello'" color="accent" aria-label="Vai al carrello">Vai al carrello</button>
    </div>
  </div>
</form>
