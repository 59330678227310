<!-- <button style="margin-left: 1%;" type="button" mat-flat-button color="primary" (click)="indietro()">
    <fa-icon aria-hidden="true" [icon]="iconArrowLeft" style="margin-right: 5%;"></fa-icon>Torna alla ricerca
</button> -->

<br>
<br>

<div class="pagina">
    <div class="filtri">
        <div style="font-weight: bold; margin-bottom: 5%;">
            <fa-icon aria-hidden="true" [icon]="iconSortAmountUp" style="margin-right: 3%;"></fa-icon>FILTRA L'ELENCO
        </div>
        <div [formGroup]="form">
            <div class="entePanel">
                <div class="enteTitolo">
                    Ente<fa-icon aria-hidden="true" [icon]="iconPlus" style="margin-right: 3%; cursor: pointer;"
                        (click)="showEnteForm = !showEnteForm"></fa-icon>
                </div>
                <div *ngIf="showEnteForm == true">
                    <mat-form-field class="enteForm" appearance="fill">
                        <mat-label>Ente</mat-label>
                        <mat-select formControlName="ente">
                            <mat-option *ngFor="let comune of listaComune" [value]="comune.ente">
                                <span *ngIf="comune.ente.length == 2"
                                    style="font-weight: bold;">{{comune.denominazione}}</span>
                                <span *ngIf="comune.ente.length != 2">{{comune.denominazione}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="denominazionePanel">
                <div class="denominazioneTitolo">
                    Denominazione<fa-icon aria-hidden="true" [icon]="iconPlus"
                        style="margin-right: 3%; cursor: pointer;"
                        (click)="showDenominazioneForm = !showDenominazioneForm"></fa-icon>
                </div>
                <div *ngIf="showDenominazioneForm == true">
                    <mat-form-field class="denominazioneForm" appearance="fill">
                        <mat-label>Denominazione</mat-label>
                        <input matInput formControlName="denominazione">
                    </mat-form-field>
                </div>
            </div>
            <div class="strutturaComunalePanel">
                <div class="strutturaComunaleTitolo">
                    Tipo struttura<fa-icon aria-hidden="true" [icon]="iconPlus"
                        style="margin-right: 3%; cursor: pointer;"
                        (click)="showStrutturaComunaleForm = !showStrutturaComunaleForm"></fa-icon>
                </div>
                <div *ngIf="showStrutturaComunaleForm == true">
                    <mat-form-field class="strutturaComunaleForm" appearance="fill">
                        <mat-label>Tipo struttura</mat-label>
                        <mat-select formControlName="strutturaComunale">
                            <mat-option>-- Seleziona --</mat-option>
                            <mat-option *ngFor="let tipoStruttura of listaTipoStruttura" [value]="tipoStruttura.id">
                                {{tipoStruttura.descrizione}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="settorePanel">
                <div class="settoreTitolo">
                    Settore<fa-icon aria-hidden="true" [icon]="iconPlus" style="margin-right: 3%; cursor: pointer;"
                        (click)="showSettoreForm = !showSettoreForm"></fa-icon>
                </div>
                <div *ngIf="showSettoreForm == true">
                    <mat-form-field class="settoreForm" appearance="fill">
                        <mat-label>Settore</mat-label>
                        <mat-select formControlName="settore">
                            <mat-option>-- Seleziona --</mat-option>
                            <mat-option *ngFor="let settore of listaSettore" [value]="settore.id">
                                {{settore.descrizione}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="attivitaPanel">
                <div class="attivitaTitolo">
                    Attività<fa-icon aria-hidden="true" [icon]="iconPlus" style="margin-right: 3%; cursor: pointer;"
                        (click)="showAttivitaForm = !showAttivitaForm"></fa-icon>
                </div>
                <div *ngIf="showAttivitaForm == true">
                    <mat-form-field class="attivitaForm" appearance="fill">
                        <mat-label>Attività</mat-label>
                        <mat-select formControlName="attivita">
                            <mat-option>-- Seleziona --</mat-option>
                            <mat-option *ngFor="let attivita of listaAttivita" [value]="attivita.id">
                                {{attivita.descrizione}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="destinatariPanel">
                <div class="destinatariTitolo">
                    Destinatari<fa-icon aria-hidden="true" [icon]="iconPlus" style="margin-right: 3%; cursor: pointer;"
                        (click)="showDestinatariForm = !showDestinatariForm"></fa-icon>
                </div>
                <div *ngIf="showDestinatariForm == true">
                    <mat-form-field class="destinatariForm" appearance="fill">
                        <mat-label>Destinatari</mat-label>
                        <mat-select formControlName="destinatari">
                            <mat-option>-- Seleziona --</mat-option>
                            <mat-optgroup label="Persone fisiche" [disabled]="false">
                                <mat-option *ngFor="let target of listaTargetPF" [value]="target.id">
                                    {{target.descrizione}}
                                </mat-option>
                            </mat-optgroup>
                            <mat-optgroup label="Persone non fisiche" [disabled]="false">
                                <mat-option *ngFor="let target of listaTargetPNF" [value]="target.id">
                                    {{target.descrizione}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div style="width: 90%; display: flex; justify-content: flex-end;" class="mat-button-bar">
                <button mat-flat-button color="accent" (click)="cerca()">Cerca</button>
            </div>
        </div>
    </div>
    <div class="risultati">
        <mat-progress-bar *ngIf="inRicerca" mode="indeterminate"></mat-progress-bar>
        <div *ngIf="listaPosizioni.length == 0 && !inRicerca && !erroreRicerca">
            Non ci sono risultati per questa ricerca
        </div>
        <div *ngIf="!inRicerca && erroreRicerca">
            Errore durante la ricerca
        </div>
        <div *ngFor="let element of listaPosizioni"
            style="padding-left: 2%; border-left: 2px solid grey; margin-bottom: 5%;">
            <div class="comune">
                Comune di {{element.comune}}
            </div>
            <div class="posizione">
                {{element.nomePosizione}}
            </div>
            <div class="descrizione">
                {{element.descrizione}}
            </div>
            <div class="info">
                <div class="settoreInfoContainer">
                    <div class="settoreInfoTitolo">
                        <fa-icon aria-hidden="true" [icon]="iconFolder" style="margin-right: 5%;"></fa-icon>Settore
                    </div>
                    <div *ngIf="element.settoreObj.id == 11">
                        {{element.settoreObj.descrizione_altro}}
                    </div>
                    <div *ngIf="element.settoreObj.id != 11">
                        {{element.settoreObj.descrizione}}
                    </div>
                </div>
                <div class="attivitaInfoContainer">
                    <div class="attivitaInfoTitolo">
                        <fa-icon aria-hidden="true" [icon]="iconFlag" style="margin-right: 5%;"></fa-icon>Attività
                    </div>
                    <div *ngIf="element.attivitaObj.id == 26">
                        {{element.attivitaObj.descrizione_altro}}
                    </div>
                    <div *ngIf="element.attivitaObj.id != 26">
                        {{element.attivitaObj.descrizione}}
                    </div>
                </div>
                <div class="destinatariInfoContainer">
                    <div class="destinatariInfoTitolo">
                        <fa-icon aria-hidden="true" [icon]="iconBullseye" style="margin-right: 5%;"></fa-icon>
                        Destinatari
                    </div>
                    <div *ngFor="let target of element.listaTarget">
                        <div>
                            {{target}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mat-button-bar">
                <button mat-flat-button color="accent" (click)="candidati(element)">Candidati</button>
            </div>
        </div>
        <mat-paginator [style.display]="totalElements > 5 ? 'block' : 'none'" [length]="totalElements"
            [pageIndex]="page" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons (page)="paginazione($event)">
        </mat-paginator>
    </div>
</div>