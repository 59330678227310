<ng-template #template>

  <!-- <mat-form-field appearance="{{'appearance'|global}}">
    <mat-label>Ordina per</mat-label>
    <mat-select [(value)]="sortColumnId">
      <mat-option>Ordinamento predefinito</mat-option>
      <mat-option *ngFor="let column of tableColumns" value="{{column.id}}">{{column.label}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-button-toggle-group #group="orderToggleGroup">
    <mat-button-toggle value="asc" aria-label="Text align left">
      <mat-icon>format_align_left</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="desc" aria-label="Text align center">
      <mat-icon>format_align_center</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group> -->


  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
    <mat-form-field *ngIf="showTable && showFilter" fxFlex fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}">
      <mat-label>Filtro</mat-label>
      <input matInput [(ngModel)]="tableDataSource.filter" placeholder="Inserire un testo">
    </mat-form-field>
  </div>

  <table #tableElementRef *ngIf="showTable" fxFlex mat-table matTableResponsive matSort multiTemplateDataRows [dataSource]="tableDataSource"
  role="table">
    <ng-container *ngFor="let column of tableColumns" matColumnDef="{{column.id}}">
      <th id="{{column.id}}" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" [matTooltip]="column.tooltip">
        <ng-container *ngIf="!column.hasIcon">
          <mat-label [attr.aria-label]="column.ariaLabel">{{column.label}}</mat-label>
        </ng-container>
        <ng-container *ngIf="column.hasIcon">
          <fa-icon [icon]="column.label" aria-label="{{column.ariaLabel}}"></fa-icon>
        </ng-container>
      </th>
      <tr>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="column.id!=='tableExpandColumn' && (!column.actions || column.enabledActions(tableId, parentRef, element).length == 0)">
            <mat-label *ngIf="column.type==='text' || getProp(element,column.id) === null"
                       [ngClass]="{'clickable': isCellClickable(tableId, parentRef, element, column)}"
                       (click)="cellClick(tableId, parentRef, element, column)">
              {{getProp(element,column.id) | dynamicPipe: column.pipe:column.pipeArgs}}
            </mat-label>
            <img *ngIf="column.type==='img64' && getProp(element,column.id)" class="thumbnail-ente" src="{{'data:image/png;base64,'+getProp(element,column.id)}}" alt="Immagine Ente" aria-hidden="true">
          </ng-container>
          <ng-container *ngIf="column.actions && column.enabledActions(tableId, parentRef, element).length > 0">
            <div mat-button-row class="button-row" aria-label="Azioni disponibili">
              <!-- <div class="float-button-container" [class.active]="showFloatingButtons(tableId, element)">
                <fa-layers class="floating-buttons" *ngFor="let action of column.enabledActions(tableId, parentRef, element)" [fixedWidth]="true">
                  <fa-icon
                    [icon] = "action.icon"
                    (click)= "action.click(tableId, element, parentRef, $event)"
                    [matTooltip]="action.tooltip" ></fa-icon>
                  <fa-layers-text *ngIf="action.overlayText" [content]="action.overlayText.text" [class]="action.overlayText.class" [style]="action.overlayText.style" [transform]="action.overlayText.transform"></fa-layers-text>
                </fa-layers>
              </div>
              <fa-icon [icon]="iconEllipsisH" (click)="toggleFloat(element, $event)"></fa-icon> -->
              <button mat-icon-button aria-label="element[column.ariaLabel]" role="button"
              [matMenuTriggerFor]="menu" (click)="openActionMenu(element, $event)">
              <fa-icon [icon]="iconEllipsisH"></fa-icon></button>
              <mat-menu #menu="matMenu" class="menu-detail-panel" xPosition="before">
                <ng-template matMenuContent>
                  <button mat-menu-item class="menu-detail-item"
                    *ngFor="let action of column.enabledActions(tableId, parentRef, element)"
                    (click)= "action.click(tableId, element, parentRef, $event)"
                    aria-label="action."
                    role="button" tabindex="0"
                    >
                    <!-- fa-icon [icon] = "action.icon"></fa-icon -->
                    <span>{{action.tooltip}}</span>
                  </button>
                </ng-template>
              </mat-menu>
            </div>
          </ng-container>
          <ng-container *ngIf="column.id==='tableExpandColumn'">
            <button mat-icon-button *ngIf="hasDetail && (onClickRowFun || element.details!=null)"
            class="mypa-table-expansion-indicator" role="button" aria-label="element[column.ariaLabel]" tabindex="0">
            </button>
          </ng-container>
        </td>
      </tr>
      <tr *ngIf="showTotalFooter">
        <ng-container *ngIf="column.id!=='tableExpandColumn' && column.totalLabel">
          <td mat-footer-cell *matFooterCellDef>Totale</td>
        </ng-container>
        <ng-container *ngIf="column.id!=='tableExpandColumn' && !column.totalLabel">
          <td mat-footer-cell *matFooterCellDef>{{getTotal(column.id) | currency}}</td>
        </ng-container>
        <ng-container *ngIf="column.id==='tableExpandColumn'">
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      </tr>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="tableColumns.length">
        <div *ngIf="element.details!=null" class="mypa-element-detail"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

          <table class="mat-table-details" mat-table [dataSource]="element.details | detailfilter:detailFilterInclude:detailFilterExclude">
            <ng-container matColumnDef="key">
              <th mat-header-cell *matHeaderCellDef> key </th>
              <td mat-cell *matCellDef="let detailElement"> 
                <span *ngIf="detailElement.key == 'Pagina dettaglio'">
                  Per consultare tutte le informazioni sull'associazione cliccare sul link di dettaglio
                </span>
                <span *ngIf="detailElement.key != 'Pagina dettaglio'">
                  {{detailElement.key}} 
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef> value </th>
              <td mat-cell *matCellDef="let detailElement"> 
                <span *ngIf="detailElement.key != 'Pagina dettaglio' && detailElement.key != 'Procedi alla candidatura'">
                  {{detailElement.value}}
                </span>
                <span *ngIf="detailElement.key == 'Pagina dettaglio'">
                  <a [routerLink]="['/dettaglio-associazione/' + detailElement.value]">Dettaglio</a>
                </span>
                <span *ngIf="detailElement.key == 'Procedi alla candidatura'">
                  <a [routerLink]="['/dettaglio-associazione/' + detailElement.value]">Candidati</a>
                </span>
              </td>
            </ng-container>
            <tr mat-row class="mypa-nested-table-row" *matRowDef="let detailElement; columns: tableDatailColumnsName;"></tr>
          </table>

        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumnsName"></tr>
    <tr mat-row *matRowDef="let element; columns: tableColumnsName;"
        class="mypa-element-row mypa-element-row-alternate-withdetail"
        [ngClass]="{'cursor-pointer': hasDetail}"
        [style]="rowStyle(element)"
        [class.mypa-expanded-row]="expandedElement === element"
        (click)="onClickRow(element)"
        tabindex="0"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mypa-detail-row"></tr>
    <ng-container *ngIf="showTotalFooter">
      <tr mat-footer-row *matFooterRowDef="tableColumnsName; sticky: true"></tr>
    </ng-container>
  </table>

<mat-paginator fxFlex [style.display]="tableDataSource.data?.length>5 ? 'block' : 'none'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</ng-template>
