<div
     fxFlex
     fxLayout="column"
     fxLayout.gt-sm="row wrap"
     fxLayoutAlign="start center"
     fxLayoutGap="10px">

    <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
      <mat-card-header fxFlex="0 1 auto">
        <div mat-card-avatar><fa-icon [icon]="iconInfo" size="2x" aria-hidden="true"></fa-icon></div>
        <mat-card-title><h1>Benvenuto su MyPa</h1></mat-card-title>
        <mat-card-subtitle>Il portale dei Pagamenti della Regione del Veneto</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div>
          <img style="float: right" src="assets/images/pagoPa.png" alt="Immagine pagoPa" aria-hidden="true"/>
          <p class="mb-4">All'interno del Portale potrai agire come utente Anonimo o come utente Autenticato.</p>
          <p>Per i servizi di pagamento in modalità autenticata accedi all'<a [routerLink]="" (click)="openLoginForm()"><b>Area Personale del Cittadino</b></a>.</p>
          <p>Per accedere usa le tue credenziali di identità digitale SPID.</p>
          <p>Per avere maggiori informazioni su SPID collegati a <a href="http://www.spid.gov.it/"> "http://www.spid.gov.it/" </a>. </p>
          <p>Per richiedere le credenziali SPID collegati a <a href="http://www.spid.gov.it/richiedi-spid">"http://www.spid.gov.it/richiedi-spid"</a> e segui le istruzioni.</p>
        </div>
      </mat-card-content>
    </mat-card>

    <div fxShow="false" fxShow.gt-sm class="flex-row-break"></div>

    <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
      <mat-card-header fxFlex="0 1 auto">
        <div mat-card-avatar><a tabindex="-1" class="no-decoration" routerLink="/avvisi"><fa-icon aria-hidden="true" [icon]="iconFileInvoice" size="2x"></fa-icon><span class="sr-only">Avvisi di pagamento</span></a></div>
        <mat-card-title><a tabindex="-1" class="no-decoration" routerLink="/avvisi">Avvisi di pagamento</a></mat-card-title>
        <mat-card-subtitle><a tabindex="-1" class="no-decoration" routerLink="/avvisi">Cerca e paga un avviso di pagamento</a></mat-card-subtitle>
      </mat-card-header>
      <div fxFlex></div>
      <mat-card-actions fxFlex="0 1 auto">
        <a mat-button routerLink="/avvisi"><span class="font-bold"><u>Cerca avviso</u> &nbsp;</span><fa-icon [icon]="iconAngleRight"></fa-icon></a>
      </mat-card-actions>
    </mat-card>


    <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
      <mat-card-header fxFlex="0 1 auto">
        <div mat-card-avatar><a tabindex="-1" class="no-decoration" routerLink="/spontaneo"><fa-icon aria-hidden="true" [icon]="iconTags" size="2x"></fa-icon><span class="sr-only">Altre tipologie di pagamento</span></a></div>
        <mat-card-title><a tabindex="-1" class="no-decoration" routerLink="/spontaneo">Altre tipologie di pagamento</a></mat-card-title>
        <mat-card-subtitle><a tabindex="-1"   class="no-decoration" routerLink="/spontaneo">Scegli un ente e il tipo di pagamento spontaneo da
          effettuare, compila i campi richiesti e procedi col pagamento</a></mat-card-subtitle>
      </mat-card-header>
      <div fxFlex></div>
      <mat-card-actions fxFlex="0 1 auto">
        <a mat-button routerLink="/spontaneo"><span class="font-bold"><u>Ricerca altri pagamenti</u> &nbsp;</span><fa-icon [icon]="iconAngleRight"></fa-icon></a>
      </mat-card-actions>
    </mat-card>
</div>
