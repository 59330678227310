import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export class MenuItem {
  id: number;
  url: string;
  label: string | MenuItemLabel;
  active: boolean;
  external: boolean;
  position: string;
  icon: string | IconDefinition;
  submenu: MenuItem[];
  parent: MenuItem;
  roles: string[];
  needEnte: boolean;


  logged: boolean;
  anonymous: boolean;
  iconBadgeFun: () => string;
  dontHide: boolean;
  ariaLabel: string;

  auth: boolean;

  constructor(id:number, url: string, label: string | MenuItemLabel, options: {
              active?: boolean,
              external?: boolean,
              logged?: boolean,
              anonymous?: boolean,
              icon?: string | IconDefinition,
              iconBadgeFun?: () => string,
              submenu?: MenuItem[],
              position?: string,
              dontHide?: boolean,
              ariaLabel?: string,
              roles?: string[],
              needEnte?: boolean } ){
    this.id = id;
    this.url = url;
    this.label = label;
    this.active = options?.active ?? false;
    this.external = options?.external ?? false;
    this.logged = options?.logged ?? true;
    this.anonymous = options?.anonymous ?? true;
    this.icon = options?.icon;
    this.iconBadgeFun = options?.iconBadgeFun;
    this.submenu = options?.submenu;
    this.position = options?.position ?? 'center';
    this.dontHide = options?.dontHide ?? false;
    this.ariaLabel = options?.ariaLabel || label?.toString();
    this.roles = options?.roles;
    this.needEnte = options?.needEnte ?? true;
  }

  hasImgIcon(){
    return this.icon && typeof this.icon === 'string';
  }

  hasFAIcon(){
    return this.icon && typeof this.icon !== 'string';
  }

  hasFABadge(){
    return this.iconBadgeFun?.()!=null;
  }

  getSidebarLabel(){
    return (this.label instanceof MenuItemLabel)?this.label.sidebar:this.label;
  }
  getHeaderLabel(){
    return (this.label instanceof MenuItemLabel)?this.label.header:this.label;
  }
  getAriaLabel(){
    return this.ariaLabel || this.getHeaderLabel();
  }
  getId(){
    return this.id;
  }
}

export class MenuItemLabel{
  sidebar: string;
  header: string;

  constructor(sidebar:string, header:string){
    this.sidebar = sidebar;
    this.header = header;
  }

  toString() {
    return this.sidebar || this.header;
  }
}
