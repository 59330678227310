import { ToastrService } from 'ngx-toastr';
import { OverlaySpinnerService } from 'projects/mypa-fe-common/src/public-api';
import { first, take } from 'rxjs/operators';

import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AlreadyManagedError } from '../../model/already-managed-error';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-logged',
  templateUrl: './logged.component.html',
  styleUrls: ['./logged.component.scss']
})
export class LoggedComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastrService: ToastrService,
    private overlaySpinnerService: OverlaySpinnerService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {

    this.route.queryParams.pipe(first()).subscribe(params => {
      const loginToken = params['login_token'];
      const spinner = this.overlaySpinnerService.showProgress(this.elementRef);
      this.userService.loginToken(loginToken, true).subscribe( user => {
        this.overlaySpinnerService.detach(spinner);
        const welcomeMessage = "Autenticato come "+user.nome+" "+user.cognome+" ("+user.codiceFiscale+")";
        this.toastrService.success(welcomeMessage, null, {positionClass:'toast-top-center'});
        this.route.data.pipe(take(1)).subscribe(data => {
          const redirectTo = data?.redirectTo || 'home-logged';
          console.log('logged, redirect to: '+redirectTo);
          this.router.navigate([redirectTo]);
        })

      }, error => {
        this.overlaySpinnerService.detach(spinner);
        if(!(error instanceof AlreadyManagedError && error.ignore))
          this.toastrService.error(error,'Errore finalizzando l\'autenticazione',{
            disableTimeOut: true,
            positionClass:'toast-top-center'
          });
      });
    });
  }

}
