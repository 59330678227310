<div class="container">
    <div class="sinistra">
        <div class="comune">
            Comune di {{struttura.sede_leg_citta}}
        </div>
        <div class="associazione">
            {{struttura.denominazione}}
        </div>
        <div class="descrizione">
            {{struttura.descrizione}}
        </div>
        <div class="indirizzoContainer">
            <div style="margin-right: 3%;">
                <fa-icon aria-hidden="true" [icon]="iconLocationArrow"></fa-icon>
            </div>
            <div>
                <span style="font-weight: bold;">Indirizzo</span>
                <div class="indirizzo">
                    {{struttura.sede_leg_indirizzo}}
                </div>
            </div>
        </div>
        <div class="contattiContainer">
            <div style="margin-right: 3%;">
                <fa-icon aria-hidden="true" [icon]="iconUser"></fa-icon>
            </div>
            <div>
                <span style="font-weight: bold;">Contatti</span>
                <div class="contatti">
                    {{struttura.email}}
                    <br>
                    {{struttura.telefono}}
                </div>
            </div>
        </div>
    </div>
    <div class="destra">
        <div class="titolo">
            Ulteriori dettagli
        </div>
        <div class="div1">
            <div class="aChiSiRivolgeContainer">
                <div style="margin-right: 3%;">
                    <fa-icon aria-hidden="true" [icon]="iconBullseye"></fa-icon>
                </div>
                <div>
                    <span style="font-weight: bold;">A chi si rivolge</span>
                    <div class="aChiSiRivolge" *ngFor="let target of struttura.target">
                        <div>
                            {{target}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="doveOperaContainer">
                <div style="margin-right: 3%;">
                    <fa-icon aria-hidden="true" [icon]="iconMapMarked"></fa-icon>
                </div>
                <div>
                    <span style="font-weight: bold;">Dove opera</span>
                    <div class="doveOpera" *ngFor="let provincia of struttura.doveOpera">
                        <div>
                            {{provincia}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="div2">
            <div class="settoreContainer">
                <div style="margin-right: 3%;">
                    <fa-icon aria-hidden="true" [icon]="iconFolder"></fa-icon>
                </div>
                <div>
                    <span style="font-weight: bold;">Settore</span>
                    <div class="settore" *ngFor="let settore of struttura.settori">
                        <div *ngIf="settore.id == 11">
                            {{settore.descrizione_altro}}
                        </div>
                        <div *ngIf="settore.id != 11">
                            {{settore.descrizione}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="giorniDellaSettimanaContainer">
                <div style="margin-right: 3%;">
                    <fa-icon aria-hidden="true" [icon]="iconCalendarDay"></fa-icon>
                </div>
                <div>
                    <span style="font-weight: bold;">Giorni della settimana</span>
                    <div class="giorniDellaSettimana" *ngFor="let giorno of struttura.giorniDellaSettimana">
                        <div>
                            {{giorno}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="div3">
            <div class="attivitaContainer">
                <div style="margin-right: 3%;">
                    <fa-icon aria-hidden="true" [icon]="iconFlag"></fa-icon>
                </div>
                <div>
                    <span style="font-weight: bold;">Attività</span>
                    <div class="attivita" *ngFor="let attivita of struttura.attivita">
                        <div>
                            {{attivita}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="orariAttivitaContainer">
                <div style="margin-right: 3%;">
                    <fa-icon aria-hidden="true" [icon]="iconClock"></fa-icon>
                </div>
                <div>
                    <span style="font-weight: bold;">Orari attività</span>
                    <div class="orariAttivita" *ngFor="let orariAttivita of struttura.orariAttivita">
                        <div>
                            {{orariAttivita}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>