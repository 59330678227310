import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  htmlContent: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.htmlContent = this.domSanitizer.sanitize(SecurityContext.HTML, this.data?.message);
  }
}
