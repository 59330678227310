import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'my-pay-help-field',
  templateUrl: './help-field.component.html',
  styleUrls: ['./help-field.component.scss']
})
export class HelpFieldComponent implements OnInit {

  iconTimes = faTimes;
  iconQuestionCircle = faQuestionCircle;

  @Input('template') templateRef: TemplateRef<any>

  constructor(
    private dialog:MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  openHelp(templateHelpDialog: TemplateRef<any>): void {
    this.dialog.open(templateHelpDialog, {panelClass: 'mypa-page-help-panel', autoFocus:false, data: {templateRef: this.templateRef}});
  }

}
