<div mat-dialog-title fxLayout="row" fxLayoutAlign="start start">
  <h2>Conferma operazione</h2>
</div>

<mat-dialog-content>
  <div [innerHtml]="htmlContent"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="accent">Annulla</button>
  <button mat-flat-button mat-dialog-close="true" color="accent">Conferma</button>
</mat-dialog-actions>
