import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { mapper } from '../mapper/mapper';
import { Mappers } from '../mapper/mappers';
import { ProcessHTTPMsgService } from './process-httpmsg.service';

@Injectable({
  providedIn: 'root'
})
export class ApiInvokerService {

  constructor(
    private httpClient: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService,
    private toastrService: ToastrService
  ) { }

  get<T>(targetUrl: string, options?:Object, mappers?:Mappers):Observable<T> {
    const finalMapper = mappers?.responseMapper || (resp => resp);
    return this.httpClient.get(targetUrl, options || {} )
    .pipe(
      first(),
      map( resp => this.mapperFunction(finalMapper(this.mapPartialResultList(resp)), mappers, 'S2C') ),
      catchError(options?.['skipHandleError'] ? this.processHTTPMsgService.skipHandleError : this.processHTTPMsgService.handleError)
    );
  }

  post<T>(targetUrl: string, body: any, options?:Object, mappers?:Mappers):Observable<T> {
    const finalMapper = mappers?.responseMapper || (resp => resp);
    return this.httpClient.post(targetUrl, this.mapperFunction(body, mappers, 'C2S'), options || {} )
    .pipe(
      first(),
      map( resp => this.mapperFunction(finalMapper(resp), mappers, 'S2C') ),
      catchError(this.processHTTPMsgService.handleError)
    );
  }

  delete<T>(targetUrl: string, options?:Object, mappers?:Mappers):Observable<T> {
    const finalMapper = mappers?.responseMapper || (resp => resp);
    return this.httpClient.delete(targetUrl, options || {} )
    .pipe(
      first(),
      map( resp => this.mapperFunction(finalMapper(this.mapPartialResultList(resp)), mappers, 'S2C') ),
      catchError(options?.['skipHandleError'] ? this.processHTTPMsgService.skipHandleError : this.processHTTPMsgService.handleError)
    );
  }

  private mapperFunction(objToMap: any, mappers: Mappers, type: string): any {
    const mapperDef = mappers?.['mapper'+type] || mappers?.mapper;
    if(mapperDef?.['MAPPER_'+type+'_DEF']){
      if(Array.isArray(objToMap)){
        return objToMap.map( item => mapper(item, mapperDef['MAPPER_'+type+'_DEF']) );
      } else {
        return mapper(objToMap, mapperDef['MAPPER_'+type+'_DEF']);
      }
    } else {
      return objToMap;
    }
  }

  private mapPartialResultList(response: any): any {
    if(response && typeof response.count!=='undefined' && typeof response?.limit!=='undefined' && typeof response?.list!=='undefined'){
      if(response.count > response.limit){
        this.toastrService.warning('Affinare la ricerca con filtri più selettivi.',`Sono mostrati ${response.limit} risultati su ${response.count} risultati trovati.`,{
          timeOut: 5000,
          closeButton:true,
          positionClass:'toast-top-center'
        });
      }
      return response.list;
    } else {
      return response;
    }
  }

}
