

import { ItemCarrello } from './item-carrello';
import { Person } from './person';

export class Carrello {
  totalAmount: number;
  items: ItemCarrello[];
  //detail of intestatario
  versante: Person;

  backUrlInviaEsito: string;
}
