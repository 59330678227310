
<ng-template #helpPage>
  <div>
    <p>Su questa pagina puoi cercare e consultare i pagamenti effettuati e quelli che non sono stati completati con successo.</p>
    <p>Per quelli effettuati con successo è possibile scaricare la Ricevuta Telematica.</p>
  </div>
</ng-template>

<ng-template #helpPageTipoDovuto>
  <div>
    <p>Per selezionare un Tipo dovuto è necessario preventivamente aver selezionato un Ente.</p>
  </div>
</ng-template>

<div class="container" role="navigation">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1">Storico transazioni <my-pay-help [template]="helpPage"></my-pay-help></h1>

    <div class="w100 mypay-search-form">
      <form novalidate #sForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

          <mat-label id="sr-ente" class="sr-only">Ente</mat-label>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="50%" fxFlex.gt-md="60%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label aria-hidden="true">Ente</mat-label>
            <input aria-labelledby="sr-ente" type="text" matInput formControlName="ente" [matAutocomplete]="autoEnte">
            <mat-autocomplete #autoEnte="matAutocomplete" [displayWith]="enteDisplayFn">
              <mat-option *ngFor="let option of enteFilteredOptions | async" [value]="option">
                <img *ngIf="option.thumbLogoEnte" class="thumbnail-ente" src="{{'data:image/png;base64,'+option.thumbLogoEnte}}" alt="Immagine Ente" aria-hidden="true">
                {{option.deNomeEnte}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="formErrors['ente']">{{ formErrors.ente }}</mat-error>
          </mat-form-field>

          <!--mat-form-field fxFlex="100%" fxFlex.gt-sm="25%" fxFlex.gt-md="20%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label>Data da</mat-label>
            <input matInput required formControlName="dateFrom" [matDatepicker]="pickerFrom">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
            <mat-error *ngIf="formErrors['dateFrom']">{{ formErrors.dateFrom }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="100%" fxFlex.gt-sm="25%" fxFlex.gt-md="20%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label>Data a</mat-label>
            <input matInput required formControlName="dateTo" [matDatepicker]="pickerTo">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
            <mat-error *ngIf="formErrors['dateTo']">{{ formErrors.dateTo }}</mat-error>
          </mat-form-field-->

          <mat-form-field fxFlex="100%" fxFlex.gt-sm="17em" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label>Intervallo date</mat-label>
            <mat-date-range-input [rangePicker]="pickerDate">
              <input matStartDate formControlName="dateFrom" placeholder="data da">
              <input matEndDate formControlName="dateTo" placeholder="data a">
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerDate></mat-date-range-picker>
            <mat-error *ngIf="formErrors['dateFrom']">{{ formErrors.dateFrom }}</mat-error>
          </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

          <mat-label id="sr-dovuto" class="sr-only">Tipo dovuto</mat-label>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="50%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label aria-hidden="true">Tipo dovuto</mat-label>
            <input aria-labelledby="sr-dovuto" type="text" matInput formControlName="tipoDovuto" [matAutocomplete]="autoTipoDovuto">
            <mat-autocomplete #autoTipoDovuto="matAutocomplete" [displayWith]="tipoDovutoDisplayFn">
              <mat-option *ngFor="let optionTipoDovuto of tipoDovutoFilteredOptions | async" [value]="optionTipoDovuto">
                {{optionTipoDovuto.deTipo}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="formErrors['tipoDovuto']">{{ formErrors.tipoDovuto }}</mat-error>
          </mat-form-field>

          <mat-label id="sr-causale" class="sr-only">Causale</mat-label>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="50%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label aria-hidden="true">Causale</mat-label>
            <input aria-labelledby="sr-causale" matInput formControlName="causale">
            <mat-error *ngIf="formErrors['causale']">{{ formErrors.causale }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">

          <button type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
          <button type="submit" mat-flat-button [disabled]="form.invalid || blockingError" color="accent">Cerca</button>

        </div>
      </form>
    </div>

    <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
    <my-pay-table-cittadino [tableData]="tableData" [tableColumns]="tableColumns" [parentRef]="this" [hasDetail]="true">
    </my-pay-table-cittadino>

  </div>

</div>
