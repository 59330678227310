import { WithActions } from 'projects/mypa-fe-common/src/public-api';

import { Debito } from './debito';
import { Person } from './person';
import { Spontaneo } from './spontaneo';

export class ItemCarrello extends WithActions {
  id: number;
  causale: string;
  causaleVisualizzata: string;
  bilancio: string;
  importo: number;
  deEnte: string;
  thumbEnte: string;
  codIpaEnte: string;
  codTipoDovuto: string;
  deTipoDovuto: string;

  // Message from validateForm() when error occurs;
  errorMsg: string;
  //detail of intestatario
  intestatario: Person;
  flagSpontaneo: boolean;
  details: object[];

  static isDebito(elem: ItemCarrello): elem is Debito {
    return !this.isSpontaneo(elem);
  }

  static isSpontaneo(elem: ItemCarrello): elem is Spontaneo {
    return elem?.flagSpontaneo;
  }
}
