import { DateTime } from 'luxon';

import { MapperDef, MapperType } from '../mapper/mapper-def';

export class AppInfo {
  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.DateTime,'buildTime','unix-ms')
  ];

  gitHash: string;
  lastTag: string;
  version: string;
  buildTime: DateTime;
}
