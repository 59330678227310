import { MypaVolontariatoService } from './mypa-volontariato/mypa-volontariato.service';
import {
  ConfigurationService, MenuItem, MenuItemLabel, MyPayBreadcrumbsService
} from 'projects/mypa-fe-common/src/public-api';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  faCashRegister, faCreditCard, faFileInvoice, faHome, faQuestionCircle, faSearch, faShoppingCart, faTags,
  faUser
} from '@fortawesome/free-solid-svg-icons';

import { environment } from '../../environments/environment';
import { CarrelloService } from './carrello.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menuItemsMap: { [key: number]: MenuItem } = {};
  private selectedMenuItemFirstLevel: MenuItem;

  private menuItemsSecondLevelAnonymous: MenuItem[];
  private menuItemsSecondLevelLogged: MenuItem[];

  private menuItems: MenuItem[];
  public baseUrlMyPa: string;

  constructor(
    private router: Router,
    carrelloService: CarrelloService,
    private myPayBreadcrumbsService: MyPayBreadcrumbsService,
    conf: ConfigurationService,
    private myPaVolontariatoService: MypaVolontariatoService
  ) {

    this.myPaVolontariatoService.getConfiguration().subscribe(
      response => {
        this.baseUrlMyPa = response[0];
        const baseMyPAUrl = conf.getProperty('baseMyPAUrl', environment);
        const carrelloBadgeFun = () => carrelloService.size() > 0 ? ("" + carrelloService.size()) : null;
        this.menuItems = [
          new MenuItem(10, baseMyPAUrl + 'a/utente/fascicolo/bacheca', 'Bacheca', { external: true, anonymous: false }),
          new MenuItem(11, baseMyPAUrl + 'a/utente/fascicolo/scadenze', 'Scadenze', { external: true, anonymous: false }),
          new MenuItem(12, baseMyPAUrl + 'a/utente/fascicolo/domande', 'Domande', { external: true, anonymous: false }),
          /* new MenuItem(13,'/home', 'Pagamenti', {active: true, submenu: [new MenuItem(20,'/home', 'Home', {logged: false, icon:faHome}),
            new MenuItem(21,'/cards', 'Home', {anonymous: false, icon:faHome}),
            new MenuItem(22,'/debiti', 'Posizioni aperte', {anonymous: false, icon:faCashRegister}),
            new MenuItem(23,'/pagati', 'Storico transazioni', {anonymous: false, icon:faCreditCard}),
            new MenuItem(24,'/avvisi', 'Avvisi di pagamento', { icon:faFileInvoice}),
            new MenuItem(32,'/spontaneo', 'Altre tipologie di pagamento', {icon:faTags}),
            new MenuItem(26,'/carrello', 'Carrello', {icon:faShoppingCart, iconBadgeFun: carrelloBadgeFun}),
          ]}), */
          new MenuItem(14, baseMyPAUrl + 'a/utente/fascicolo/avvisi', 'Avvisi', { external: true, anonymous: false }),
          new MenuItem(15, baseMyPAUrl + 'a/utente/fascicolo/preferiti', 'Preferiti', { external: true, anonymous: false }),
          //new MenuItem(16,baseMyPAUrl+'a/ricerca/marco', 'Trova il servizio con Marco', false, true, false, baseMyPAUrl+'client/application/assets/images/marco.png'),
          new MenuItem(17, baseMyPAUrl + 'a/utente/profilo/profilo', 'Profilo e impostazioni', { external: true, anonymous: false, icon: faUser, position: 'right' }),
          new MenuItem(18, this.baseUrlMyPa + '/home', new MenuItemLabel('Home', null), { external: true, position: '', dontHide: true }),
          new MenuItem(19, this.baseUrlMyPa + '/ricerca/marco', new MenuItemLabel('Trova il servizio con Marco', null), { external: true, icon: baseMyPAUrl + 'client/application/assets/images/marco.png', position: '', dontHide: true }),
          new MenuItem(20, this.baseUrlMyPa + '/informative/faq', new MenuItemLabel('Aiuto', null), { external: true, icon: faQuestionCircle, position: 'right', dontHide: true }),

        ];
        this._buildMenuItemsMap(this.menuItems);
        myPayBreadcrumbsService.setMenuItemMap(this.menuItemsMap);
      }
    );
  }

  private _buildMenuItemsMap(array: MenuItem[], parentItem: MenuItem = null) {
    array.forEach(item => {
      item.parent = parentItem;
      this.menuItemsMap[item.id] = item;
      if (item.submenu)
        this._buildMenuItemsMap(item.submenu, item);
    });
  }

  getMenuItem(id: number): MenuItem {
    return this.menuItemsMap[id];
  }

  getFirstLevelMenuItemByUrl(url: string, parentId: number = null): MenuItem {
    const root = parentId ? this.getMenuItem(parentId)?.submenu : this.menuItems;
    const item = this._findMenuItemByUrl(root, url);
    return this._findRootItem(item);
  }

  private _findRootItem(item: MenuItem): MenuItem {
    return item?.parent ? this._findRootItem(item.parent) : item;
  }

  private _findMenuItemByUrl(array: MenuItem[], url: string): MenuItem {
    if (array) {
      for (const item of array) {
        if (item.url === url)
          return item;
        if (item.submenu) {
          const foundItem = this._findMenuItemByUrl(item.submenu, url);
          if (foundItem)
            return foundItem;
        }
      }
    }
    return null;
  }

  private firstLevelCache: Map<string, MenuItem[]> = new Map();
  getMenuFirstLevel(forLogged: boolean, onlyPosition: string = null) {
    const key = forLogged + "|" + onlyPosition;
    if (!this.firstLevelCache.has(key) && this.menuItems)
      this.firstLevelCache.set(key, this.menuItems.filter(item => (forLogged ? item.logged : item.anonymous) && (!onlyPosition || item.position === onlyPosition)));
    return this.firstLevelCache.get(key);
  }

  getMenuSecondLevel(forLogged: boolean): MenuItem[] {
    return forLogged ? this.menuItemsSecondLevelLogged : this.menuItemsSecondLevelAnonymous;
  }

  setSelectedMenuItemFirstLevel(item: MenuItem) {
    this.selectedMenuItemFirstLevel = item;
    this.menuItemsSecondLevelAnonymous = this.selectedMenuItemFirstLevel?.submenu?.filter(item => item.anonymous);
    this.menuItemsSecondLevelLogged = this.selectedMenuItemFirstLevel?.submenu?.filter(item => item.logged);
  }

  onClickFirstLevel(item: MenuItem) {
    if (item.external)
      window.location.href = item.url;
    else {
      this.setSelectedMenuItemFirstLevel(item);
      this.myPayBreadcrumbsService.resetBreadcrumbs();
      this.router.navigate([item.url]);
    }
  }

}
