import { DateTime } from 'luxon';
import {
    ApiInvokerService, BaseUrlService, Ente, Mappers, TipoDovuto
} from 'projects/mypa-fe-common/src/public-api';
import { Observable } from 'rxjs';

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Pagato } from '../model/pagato';

@Injectable({
  providedIn: 'root'
})
export class PagatoService {

  constructor(
    private baseUrlService: BaseUrlService,
    private apiInvokerService: ApiInvokerService
  ) { }

  searchPagati(ente: Ente, dateFrom: DateTime, dateTo: DateTime, causale: string, tipoDovuto: TipoDovuto): Observable<Pagato[]> {
    let params = new HttpParams();
    params = params
      .append('from', dateFrom.toFormat('yyyy/MM/dd'))
      .append('to', dateTo.toFormat('yyyy/MM/dd'));
    if(ente)
      params = params.append('codIpaEnte', ente.codIpaEnte);
    if(causale)
      params = params.append('causale', causale);
    if(tipoDovuto)
      params = params.append('codTipoDovuto', tipoDovuto.codTipo);

    return this.apiInvokerService.get<Pagato[]>
      (this.baseUrlService.getCittadinoUrl() + 'pagati/search', {params:params}, new Mappers({mapperS2C: Pagato}));
  }

  searchLastPagati(num: number = 5): Observable<Pagato[]> {
    let params = new HttpParams().append('num', ''+num);
    return this.apiInvokerService.get<Pagato[]>
      (this.baseUrlService.getCittadinoUrl() + 'pagati/last', {params:params}, new Mappers({mapperS2C: Pagato}));
  }

  downloadRt(pagato: Pagato): any {
    return this.apiInvokerService.get<any>(this.baseUrlService.getCittadinoUrl() + 'pagati/' + pagato.id + '/rt', {responseType: 'blob'});
  }

  downloadRtAnonymous(pagato: Pagato, recaptchaResponse: any): any {
    let params = new HttpParams();
    params = params.append('recaptcha', recaptchaResponse);
    return this.apiInvokerService.get<any>(this.baseUrlService.getPubCittadinoUrl() + 'pagati/' + pagato.id + '/rt', {params:params, responseType: 'blob'});
  }

}
