export class Person {
  codiceIdentificativoUnivoco: string
  tipoIdentificativoUnivoco?: 'F'|'G';
  anagrafica?: string
  email?: string
  indirizzo?: string
  civico?: string
  cap?: string
  nazione?: string
  provincia?: string
  localita?: string
  nazioneId?: number
  provinciaId?: number
  localitaId?: number

  public static ANONIMO():Person{
    const anonimo = new Person();
    anonimo.codiceIdentificativoUnivoco = '<ANONIMO>';
    return anonimo;
  }

  public static isPersonAnonimo(person: Person):boolean {
    return !person || !person.codiceIdentificativoUnivoco || person.codiceIdentificativoUnivoco==='<ANONIMO>';
  }

  public static idIntestatario(person: Person):string {
    if(!person)
      return;
    //return a simple hash (inspired by java hashcode) of "CF + name + email"
    //console.log(person.codiceIdentificativoUnivoco + "|" + person.anagrafica + "|" + person.email, Array.from(person.codiceIdentificativoUnivoco + "|" + person.anagrafica + "|" + person.email).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0));
    return Array.from(person.codiceIdentificativoUnivoco + "|" + person.anagrafica + "|" + person.email)
        .reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0).toString();
  }
}
