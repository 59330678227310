import { DateTime } from 'luxon';
import { MapperDef, MapperType } from 'projects/mypa-fe-common/src/public-api';

import { ItemCarrello } from './item-carrello';
import { Pagato } from './pagato';

export class Debito extends ItemCarrello {
  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.Currency,'importo'),
    new MapperDef(MapperType.DateTime,'dataScadenza','local-date'),
  ];

  codIud: string;
  causaleVisualizzata: string;
  valuta: string;
  codStato: string;
  dataScadenza: DateTime;
  deStato: string;
  modPagamento: string;
  avviso: boolean;
  codIuv: string;
  isMultiIntestatario: boolean;
  numeroAvviso: string;
  intestatarioAvviso: string;
  securityTokenAvviso: string;

  //calculated fields at runtime (this app)
  dataScadenzaDay: string;
  dataScadenzaMonth: string;
  dataScadenzaYear: string;
  dovutoElaborato: Pagato;
  deStatoOriginaleCarrello: string;

}
