

import { ItemCarrello } from './item-carrello';

export class Spontaneo extends ItemCarrello {

  constructor(){
    super();
    //super.id = getRandomInt(1000000000, 10000000000);
  }
}
