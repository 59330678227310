import { UserService } from 'projects/mypa-fe-common/src/public-api';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    faAngleRight, faFileInvoice, faInfoCircle, faTags
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  iconFileInvoice = faFileInvoice;
  iconTags = faTags;
  iconAngleRight = faAngleRight;
  iconInfo = faInfoCircle;

  constructor(
    private userService: UserService,
    private router: Router) { }

  ngOnInit(): void {
    if(this.userService.isLogged())
      this.router.navigate(['cards']);
  }

  openLoginForm() {
    this.userService.goToLogin();
  }
}

