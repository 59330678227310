import { MenuItem, UserService } from 'projects/mypa-fe-common/src/public-api';
import { delay } from 'rxjs/operators';

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { faSignOutAlt, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';

import { CoreAppElementsActivation, CoreAppService } from './services/core-app.service';
import { MenuService } from './services/menu.service';
import { RecaptchaService } from './services/recaptcha.service';
import { SidenavService } from './services/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'mypa-fe-cittadino';
  @ViewChild('sidenav') public sidenav: MatSidenav;

  iconUser = faUser;
  iconTimes = faTimes;
  iconSignOut = faSignOutAlt;

  captchaActivated = false;
  coreElemActivated = new CoreAppElementsActivation();


  constructor(
    private sidenavService: SidenavService,
    public menuService: MenuService,
    public userService: UserService,
    private recaptchaService: RecaptchaService,
    private coreAppService: CoreAppService) {
      this.recaptchaService.isActiveObs().pipe(delay(0)).subscribe(isActive => this.captchaActivated = isActive);
      this.coreAppService.getStateObs().pipe(delay(0)).subscribe(coreElemActivated => this.coreElemActivated = coreElemActivated);
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  onClickFirstLevel(item: MenuItem) {
    this.sidenavService.close();
    this.menuService.onClickFirstLevel(item);
  }

  closeSidenav(){
    this.sidenavService.close();
  }

  openLoginForm() {
    this.sidenavService.close();
    this.userService.goToLogin();
  }

  logout() {
    this.sidenavService.close();
    this.userService.logout();
  }
}
