<footer class="footer py-3" role="contentinfo">
  <div class="container"
      fxFlex
      fxLayout="column"
      fxLayout.lt-md="column"
      fxLayoutGap="10px">

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0">
      <div fxLayout="row wrap" fxFlex="100%" fxFlex.gt-xs="60%">
        <div fxFlex="nogrow"><a href="https://mypa.regione.veneto.it/mypa/a"><img src="assets/images/logo-mypa-neg.svg" alt="Logo MyPa" height="50"></a></div>
        <div fxFlex="nogrow" class="mt-3 ml-2"><a href="https://www.regione.veneto.it/"><img src="assets/images/logo-regione-veneto--bw.png" alt="Logo regione Veneto"></a></div>
      </div>
      <div fxFlex="grow" ngClass="text-right my-0" ngClass.xs="text-left my-3" fxFlex="100%" fxFlex.gt-xs="40%"><span class="sr-only">Seguici su:</span>
        <a mat-icon-button style="margin-left: 12px" class="btn-facebook" href="https://www.facebook.com/RegionedelVeneto" alt="Facebook"><fa-icon aria-hidden="true" [icon]="iconFacebook" size="3x"></fa-icon><span class="sr-only">Facebook</span></a>
        <a mat-icon-button style="margin-left: 12px" class="btn-instagram" href="https://www.instagram.com/regioneveneto"><fa-icon aria-hidden="true" [icon]="iconInstagram" size="3x"></fa-icon><span class="sr-only">Instagram</span></a>
        <a mat-icon-button style="margin-left: 12px" class="btn-twitter" href="https://twitter.com/regioneveneto"><fa-icon aria-hidden="true" [icon]="iconTwitter" size="3x"></fa-icon><span class="sr-only">Twitter</span></a>
        <a mat-icon-button style="margin-left: 12px" class="btn-youtube" href="https://www.youtube.com/user/RegioneDelVeneto"><fa-icon aria-hidden="true" [icon]="iconYoutube" size="3x"></fa-icon><span class="sr-only">Youtube</span></a>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start top" fxLayoutGap="0px">
      <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-sm="25%" class="mb-3">
        <h2 class="mat-h2">Contatti</h2>
        <address>
            Regione del Veneto <br>
            Palazzo Balbi - Dorsoduro, 3901 <br>
            30123 Venezia <br>
            P.IVA 02392630279 <br>
          <fa-icon [icon]="iconPhone"></fa-icon>&nbsp; (+39) 041 2792111<br>
          <fa-icon [icon]="iconEnvelope"></fa-icon>&nbsp;
          <a href="https://www.regione.veneto.it/web/affari-generali/pec-regione-veneto">Posta Elettronica Certificata</a>
        </address>
      </div>
      <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-sm="25%" class="mb-3">
        <div fxLayout="column" fxLayoutAlign="start start">
          <h2 class="mat-h2">Assistenza</h2>
          <a href="https://mypa.regione.veneto.it/mypa/a/informative/whatis">Cos’è l'Area Personale?</a>
          <a href="https://mypa.regione.veneto.it/mypa/a/informative/faq">FAQ</a>
        </div>
      </div>
      <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-sm="25%" class="mb-3">
        <div fxLayout="column" fxLayoutAlign="start start">
          <h2 class="mat-h2">Trova con myPA</h2>
          <a href="https://mypa.regione.veneto.it/mypa/a/ricerca/marco">Servizi</a>
          <a href="https://mypa.regione.veneto.it/mypa/a/ricerca/marco">Bandi e concorso Appalti</a>
          <a href="https://mypa.regione.veneto.it/mypa/a/ricerca/marco">News</a>
          <a href="https://mypa.regione.veneto.it/mypa/a/ricerca/marco">Eventi</a>
        </div>
      </div>
      <div fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-sm="25%" class="mb-3">
        <div fxLayout="column" fxLayoutAlign="start start">
          <h2 class="mat-h2">MyApp</h2>
          <a href="https://mypa.regione.veneto.it/listaenti/?_platform=DESKTOP&_cipa=&_id=1">Lista degli enti</a>
          <a href="https://mypa.regione.veneto.it/mappaenti/?_platform=DESKTOP&_cipa=&_id=2">Mappa degli enti</a>
          <a href="https://mypa.regione.veneto.it/myevent/?_platform=DESKTOP&_cipa=&_id=3">MyEvent</a>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start top" fxLayoutGap="0">
      <div fxFlex="100%" fxFlex.gt-sm="25%"><p>© 2017 Regione del Veneto</p></div>
      <div fxFlex="100%" fxFlex.gt-sm="75%" ngClass="text-right my-0" ngClass.xs="text-left my-3">
        <a class="ml-0" [routerLink]="" (click)="showAppInfo()">Versione</a>
        <a class="ml-2" href="https://mypa.regione.veneto.it/mypa/a/informative/accessibility">Accessibilità</a>
        <a class="ml-2" href="https://mypa.regione.veneto.it/mypa/a/informative/privacy">Privacy</a>
        <a class="ml-2" target="_blank" href="http://myportal-infopbl.regione.veneto.it/myportal/INFOPBL/informative/note_legali">Note Legali</a>
        <a class="ml-2" target="_blank" href="http://myportal-infopbl.regione.veneto.it/myportal/INFOPBL/informative/info_cookies">Informativa Cookie</a>
      </div>
    </div>


  </div>
  </footer>
