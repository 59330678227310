<div class="container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1">Altre tipologie di pagamento</h1>

    <mat-expansion-panel class="mypay-search-expansion-panel w100 mat-elevation-z0" [expanded]="!hasSearched || !currentTipoDovuto">
      <mat-expansion-panel-header collapsedHeight="auto">
        <div class="w100" *ngIf="hasSearched && currentTipoDovuto">
          <h2 class="mat-h2 text-center">
            <img *ngIf="currentEnte?.thumbLogoEnte" class="thumbnail-ente" src="{{'data:image/png;base64,'+currentEnte?.thumbLogoEnte}}" alt="Immagine Ente" aria-hidden="true">
            {{ currentEnte?.deNomeEnte }}
          </h2>
          <h2 class="mat-h2 text-center">Pagamento Dovuto: {{ currentTipoDovuto?.deTipo }}</h2>
        </div>
      </mat-expansion-panel-header>

      <div class="w100 mypay-search-form">
        <form novalidate #enteTipoDirective="ngForm" [formGroup]="enteTipo" (ngSubmit)="onSubmit()">
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

            <mat-label id="sr-ente" class="sr-only">Ente</mat-label>
            <mat-form-field fxFlex="100%" fxFlex.gt-sm="40%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
              <mat-label aria-hidden="true">Ente</mat-label>
              <input aria-labelledby="sr-ente" type="text" matInput formControlName="ente" [matAutocomplete]="autoEnte">
              <mat-autocomplete #autoEnte="matAutocomplete" [displayWith]="enteDisplayFn">
                <mat-option *ngFor="let option of enteFilteredOptions | async" [value]="option">
                  <img *ngIf="option.thumbLogoEnte" class="thumbnail-ente" src="{{'data:image/png;base64,'+option.thumbLogoEnte}}" alt="Immagine Ente" aria-hidden="true">
                  {{option.deNomeEnte}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="enteTipoErrors['ente']">{{ enteTipoErrors['ente'] }}</mat-error>
            </mat-form-field>

            <mat-label id="sr-dovuto" class="sr-only">Tipo Dovuto</mat-label>
            <mat-form-field fxFlex="100%" fxFlex.gt-sm="60%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
              <mat-label class="mat-label" aria-hidden="true">Tipo dovuto</mat-label>
              <input aria-labelledby="sr-dovuto" type="text" matInput formControlName="tipoDovuto" [matAutocomplete]="autoTipoDovuto">
              <mat-autocomplete #autoTipoDovuto="matAutocomplete" [displayWith]="tipoDovutoDisplayFn">
                <mat-option *ngFor="let optionTipoDovuto of tipoDovutoFilteredOptions | async" [value]="optionTipoDovuto">
                  {{optionTipoDovuto.deTipo}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="enteTipoErrors['tipoDovuto']">{{ enteTipoErrors['tipoDovuto'] }}</mat-error>
            </mat-form-field>

          </div>

          <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
            <button type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
            <button type="submit" mat-flat-button [disabled]="enteTipo.invalid || blockingError" color="accent">Procedi</button>
          </div>

        </form>
      </div>

    </mat-expansion-panel>

    <div class="w100" *ngIf="hasSearched && currentTipoDovuto" style="margin-top:20px;">

      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">

        <!-- Make sure if <app-messages> is needed. -->
        <spontaneo-dynamo [fieldBeans]="fieldBeans" [currentEnte]="currentEnte" [currentTipoDovuto]="currentTipoDovuto"
            [importoPrefissato]="importoPrefissato">
        </spontaneo-dynamo>
      </div>

    </div>

  </div>
</div>
