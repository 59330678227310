<br>
<br>

<div style="display: flex; justify-content: center; margin-bottom: 20%;">
    <div [formGroup]="form" style="width: 60%;">
        <div style="margin-bottom: 5%;">
            Si desidera dare il proprio consenso al trattamento dei dati personali all'associazione
            {{consenso.denominazione_associazione}}?
        </div>
        <div>
            <mat-radio-group formControlName="consenso">
                <mat-radio-button [value]="true" style="margin-right: 1%;">Si</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-error *ngIf="this.form.controls.consenso.errors && premutoConferma == true">
            <mat-error *ngIf="this.form.controls.consenso.errors.required">Campo obbligatorio
            </mat-error>
        </mat-error>
        <div class="mat-button-bar" style="margin-top: 5%;">
            <button mat-flat-button color="accent" (click)="confermaScelta()">Conferma</button>
        </div>
        <div *ngIf="mostraEsitoPositivo == true" style="margin-top: 5%;">
            Operazione terminata con successo, ora è possibile chiudere la pagina
        </div>
        <div *ngIf="mostraEsitoNegativo == true" style="margin-top: 5%;">
            Errore durante l'operazione
        </div>
        <div *ngIf="mostraErroreGetConsenso == true" style="margin-top: 5%;">
            Errore durante il recupero del consenso
        </div>
    </div>
</div>