import {
    ApiInvokerService, BaseUrlService, Mappers
} from 'projects/mypa-fe-common/src/public-api';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MailValidationRequest, MailValidationResponse } from '../model/mail-validation';

@Injectable({
  providedIn: 'root'
})
export class MailValidationService {

  constructor(
    private baseUrlService: BaseUrlService,
    private apiInvokerService: ApiInvokerService
  ) { }

  requestMailValidation(emailAddress: string, recaptchaResponse: any) {
    let params = new HttpParams()
      .append('emailAddress', emailAddress)
      .append('recaptcha', recaptchaResponse);

    return this.apiInvokerService.post<MailValidationRequest>(this.baseUrlService.getPubCittadinoUrl() + 'mailvalidation/request', null, {params:params})
      .pipe(map(r => {if(r.error) throwError(r.error); return r;}));
  }

  verifyMailValidation(mailValidationRequest: MailValidationRequest, recaptchaResponse: any) {
    let params = new HttpParams()
      .append('recaptcha', recaptchaResponse);

    return this.apiInvokerService.post<MailValidationResponse>(
      this.baseUrlService.getPubCittadinoUrl() + 'mailvalidation/verify', mailValidationRequest, {params:params},new Mappers({mapperS2C:MailValidationResponse}));
  }
}
