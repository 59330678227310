import { ToastrService } from 'ngx-toastr';

import { AlreadyManagedError } from '../model/already-managed-error';

export function manageError(msg: string, toastrService: ToastrService, doSomething: () => void = null): (error:any) => void {
  return function(error: any){
    if(doSomething)
      doSomething();
    if(!(error instanceof AlreadyManagedError && error.ignore))
      toastrService.error(error,msg,{
        //timeOut: 5000,
        disableTimeOut: true,
        closeButton:true,
        positionClass:'toast-top-center',
      });
  }
}
