import { ActivatedRoute } from '@angular/router';
import { Struttura } from './../../model/model-mypa-volontariato';
import { MypaVolontariatoService } from './../../services/mypa-volontariato/mypa-volontariato.service';
import { TableColumn, PaginatorData } from 'projects/mypa-fe-common/src/public-api';
import { Component, OnInit } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-esito-ricerca-associazione',
  templateUrl: './esito-ricerca-associazione.component.html',
  styleUrls: ['./esito-ricerca-associazione.component.scss']
})
export class EsitoRicercaAssociazioneComponent implements OnInit {

  public iconArrowLeft: any = faArrowLeft;

  public tableColumns: TableColumn[] = [
    new TableColumn('denominazione', 'Denominazione'),
    new TableColumn('sede_leg_citta', 'Sede'),
    new TableColumn('telefono', 'Telefono'),
    new TableColumn('email', 'Mail'),
  ];
  public tableData: Struttura[];
  public paginatorData: PaginatorData;
  public ente: string;

  constructor(private myPaVolontariatoService: MypaVolontariatoService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.ente = this.activatedRoute.snapshot.paramMap.get("ente");
    localStorage.setItem("ente", this.ente);
    this.listaStrutture();
  }

  public listaStrutture(): void {
    this.myPaVolontariatoService.listaStrutture(this.ente).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          response.entity.forEach(
            struttura => {
              /* se è una struttura comunale allora si valorizza il campo sede_leg_citta con l'unica 
              provincia presente nel campo province_territorio */
              if (struttura.tipoStruttura.id == 1) {
                struttura.sede_leg_citta = JSON.parse(struttura.province_territorio).province[0];
              }
              /* popolamento details necessario per poter visualizzare il dettaglio --> */
              struttura.details = [];
              struttura.details.push({ key: 'Pagina dettaglio', value: struttura.id });
              /* <-- */
            }
          );
          this.tableData = response.entity;
        }
      }
    );
  }

  public indietro(): void {
    window.location.href = "https://google.com";
  }

}
