<button style="margin-left: 1%;" type="button" mat-flat-button color="primary">
    <fa-icon aria-hidden="true" [icon]="iconArrowLeft" style="margin-right: 5%;"></fa-icon>Torna alla ricerca
</button>

<div class="container">
    <div fxFlex fxLayout="column" fxLayoutAlign="start center">
        <div>
            <h1 class="mat-h1">Ricerca una posizione aperta</h1>
        </div>
        <div>
            <h2 class="mat-h2">Se vuoi cercare una posizione aperta utilizza il seguente form</h2>
        </div>
        <div class="w100 mypay-search-form" aria-level="1">
            <form novalidate #sForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
                    <mat-label id="sr-ente" class="sr-only">Ente</mat-label>
                    <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}"
                        [floatLabel]="'always'">
                        <mat-label>Ente</mat-label>
                        <input aria-labelledby="sr-ente" matInput formControlName="ente">
                    </mat-form-field>

                    <mat-label id="sr-denominazione" class="sr-only">Denominazione</mat-label>
                    <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}"
                        [floatLabel]="'always'">
                        <mat-label>Denominazione</mat-label>
                        <input aria-labelledby="sr-denominazione" matInput formControlName="denominazione">
                    </mat-form-field>

                    <mat-label id="sr-strutturaComunale" class="sr-only">Struttura comunale</mat-label>
                    <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}"
                        [floatLabel]="'always'">
                        <mat-label>Struttura comunale</mat-label>
                        <input aria-labelledby="sr-strutturaComunale" matInput formControlName="strutturaComunale">
                    </mat-form-field>

                    <mat-label id="sr-settore" class="sr-only">Settore</mat-label>
                    <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}"
                        [floatLabel]="'always'">
                        <mat-label>Settore</mat-label>
                        <input aria-labelledby="sr-settore" matInput formControlName="settore">
                    </mat-form-field>

                    <mat-label id="sr-attivita" class="sr-only">Attività</mat-label>
                    <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}"
                        [floatLabel]="'always'">
                        <mat-label>Attività</mat-label>
                        <input aria-labelledby="sr-attivita" matInput formControlName="attivita">
                    </mat-form-field>

                    <mat-label id="sr-destinatari" class="sr-only">Destinatari</mat-label>
                    <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}"
                        [floatLabel]="'always'">
                        <mat-label>Destinatari</mat-label>
                        <input aria-labelledby="sr-destinatari" matInput formControlName="destinatari">
                    </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
                    <button type="submit" mat-flat-button [disabled]="form.invalid" color="accent">Cerca</button>
                </div>
            </form>
        </div>

        <p *ngIf="tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
        <my-pay-table-cittadino [tableData]="tableData" [tableColumns]="tableColumns" [parentRef]="this"
            [paginatorData]="paginatorData">
        </my-pay-table-cittadino>
    </div>
</div>