<!-- <button style="margin-left: 1%;" type="button" mat-flat-button color="primary" (click)="indietro()">
    <fa-icon aria-hidden="true" [icon]="iconArrowLeft" style="margin-right: 5%;"></fa-icon>Torna ad avvisi
</button> -->

<br>
<br>

<div style="display: flex; justify-content: center; margin-bottom: 20%;">
    <div *ngIf="!isIdCorretto" style="margin-bottom: 10%;">
        Identificativo della posizione non corretto
    </div>
    <div *ngIf="isIdCorretto" style="width: 60%;">
        <div class="comune">
            Comune di {{posizione.comune}}
        </div>
        <div class="posizione">
            {{posizione.nomePosizione}}
        </div>
        <div class="descrizione">
            {{posizione.descrizione}}
        </div>
        <div class="info">
            <div class="settoreInfoContainer">
                <div class="settoreInfoTitolo">
                    <fa-icon aria-hidden="true" [icon]="iconFolder" style="margin-right: 5%;"></fa-icon>Settore
                </div>
                <div *ngIf="posizione.settoreObj && posizione.settoreObj.id && posizione.settoreObj.id == 11">
                    {{posizione.settoreObj.descrizione_altro}}
                </div>
                <div *ngIf="posizione.settoreObj && posizione.settoreObj.id && posizione.settoreObj.id != 11">
                    {{posizione.settoreObj.descrizione}}
                </div>
            </div>
            <div class="attivitaInfoContainer">
                <div class="attivitaInfoTitolo">
                    <fa-icon aria-hidden="true" [icon]="iconFlag" style="margin-right: 5%;"></fa-icon>Attività
                </div>
                <div *ngIf="posizione.attivitaObj && posizione.attivitaObj.id && posizione.attivitaObj.id == 26">
                    {{posizione.attivitaObj.descrizione_altro}}
                </div>
                <div *ngIf="posizione.attivitaObj && posizione.attivitaObj.id && posizione.attivitaObj.id != 26">
                    {{posizione.attivitaObj.descrizione}}
                </div>
            </div>
            <div class="destinatariInfoContainer">
                <div class="destinatariInfoTitolo">
                    <fa-icon aria-hidden="true" [icon]="iconBullseye" style="margin-right: 5%;"></fa-icon>
                    Destinatari
                </div>
                <div *ngFor="let target of posizione.listaTarget">
                    <div>
                        {{target}}
                    </div>
                </div>
            </div>
        </div>
        <div class="mat-button-bar">
            <button mat-flat-button color="accent" (click)="candidati(posizione)">Candidati</button>
        </div>
    </div>
</div>