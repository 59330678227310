import {
    ConfigurationService, genericRetryStrategy
} from 'projects/mypa-fe-common/src/public-api';
import { BehaviorSubject, from, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, first, flatMap, retryWhen } from 'rxjs/operators';

import { Injectable, Renderer2 } from '@angular/core';

declare var grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  private ready = new ReplaySubject<any>(1);
  private active = new BehaviorSubject<boolean>(false);

  private enabled: boolean;
  private siteKey: string;

  constructor(
    private conf: ConfigurationService
  ) {
  }

  public isActiveObs():Observable<boolean> {
    return this.active.asObservable();
  }
  public deactivate() {
    //console.log('recaptcha: deactivating');
    if(this.enabled && this.active.getValue()){
      this.active.next(false);
      //console.log('recaptcha: deactivated');
    }
  }

  public init(renderer: Renderer2) {
    if(typeof grecaptcha === 'undefined'){
      console.log("init recaptcha");
      this.siteKey = this.conf.getBackendProperty('recaptchaSiteKey');
      this.enabled = this.siteKey ? true : false;
      console.log("recaptcha enabled: "+this.enabled);
      if(this.enabled){
        const script = renderer.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
        renderer.appendChild(document.head, script);
        this.ready.next(true);
        console.log("init recaptcha: done");
      }
    }
    //console.log('recaptcha: activating');
    if(this.enabled)
      this.active.next(true);
  }

  public submitToken(action: string){
    if(this.enabled)
      return this.ready.pipe(
        flatMap(() => from(grecaptcha.execute(this.siteKey, {action: action}))),
        retryWhen(genericRetryStrategy({scalingDuration: 100})),
        catchError(error => {
          console.log('error submitting captcha', error);
          return throwError(error || 'Errore durante la verifica del codice reCaptcha');
        }),
        first()
      );
    else
      return of('reCaptcha_disabled');
  }

}
