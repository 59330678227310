import { MypaVolontariatoService } from './../../services/mypa-volontariato/mypa-volontariato.service';
import {
  MenuItem, MyPayBreadcrumbsService, UserService
} from 'projects/mypa-fe-common/src/public-api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  faBars, faBell, faEllipsisH, faSignInAlt, faSignOutAlt, faTimes, faUser
} from '@fortawesome/free-solid-svg-icons';

import { MenuService } from '../../services/menu.service';
import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public isDettaglioPosizioneConsenso: boolean;
  public baseUrlMyPa: string;

  iconBars = faBars;
  iconBell = faBell;
  itemSignOut = faSignOutAlt;
  iconHeader = faEllipsisH;
  iconSignIn = faSignInAlt;
  iconUser = faUser;
  classHeader = '';

  onClickButtonHeader() {
    if (this.iconHeader == faEllipsisH) {
      this.iconHeader = faTimes;
      this.classHeader = 'is-open';
    } else {
      this.iconHeader = faEllipsisH;
      this.classHeader = null;
    }
  }

  notifications: string[];

  logged: boolean = false;
  secondLevelMenu: MenuItem[] = null;

  private loggedUserSub: Subscription;

  constructor(
    public userService: UserService,
    public menuService: MenuService,
    public myPayBreadcrumbsService: MyPayBreadcrumbsService,
    private sidenav: SidenavService,
    private router: Router,
    private myPaVolontariatoService: MypaVolontariatoService) { }

  ngOnInit(): void {
    //controllo se sono sulla pagina di dettaglio posizione
    this.isDettaglioPosizioneConsenso = window.location.href.includes("dettaglio-posizione-aperta") || window.location.href.includes("invia-consenso-privacy");

    this.myPaVolontariatoService.getConfiguration().subscribe(
      response => {
        this.baseUrlMyPa = response[0];
      }
    );

    // manage the configuration and set the second level menu
    const routerSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        const url = (<NavigationEnd>event).urlAfterRedirects || (<NavigationEnd>event).url;
        const firstLevelMenuItem = this.menuService.getFirstLevelMenuItemByUrl(url);
        if (firstLevelMenuItem) {
          this.menuService.setSelectedMenuItemFirstLevel(firstLevelMenuItem);
          this.secondLevelMenu = this.menuService.getMenuSecondLevel(this.logged);
          routerSub.unsubscribe();
        }
      });
    this.logged = this.userService.isLogged();
    this.loggedUserSub = this.userService.getLoggedUserObs().subscribe(user => {
      this.logged = user != null;
      this.secondLevelMenu = this.menuService.getMenuSecondLevel(this.logged);
    });
  }

  ngOnDestroy(): void {
    this.loggedUserSub?.unsubscribe();
  }

  openLoginForm() {
    this.userService.goToLogin();
  }

  logout() {
    this.userService.logout();
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  onClickFirstLevel(item: MenuItem) {
    this.menuService.onClickFirstLevel(item);
  }

  onClickNotification() {
    console.log("old notif:", this.notifications)
    if (Math.floor(Math.random() * 5) === 0)
      return;
    if (Math.floor(Math.random() * 3) === 0)
      this.notifications = null;
    else
      this.notifications = [...Array(Math.floor(Math.random() * 5)).keys()].map(x => "Notifica di test (" + x + "-" + (Math.floor(Math.random() * 1000)) + ")");
    console.log("new notif:", this.notifications)
  }

  trovaConMarco() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/ricerca/marco`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/ricerca/marco`;
  }

  accountSPID() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/utente`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/utente`;
  }

  accountSocial() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/autenticati?authType=socialAuth`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/autenticati?authType=socialAuth`;
  }

  differenzeSPIDSocial() {
    /* window.open(`http://myportal-infopbl.regione.veneto.it/myportal/INFOPBL/informative/testo_informativi`, '_blank'); */
    window.location.href = "http://myportal-infopbl.regione.veneto.it/myportal/INFOPBL/informative/testo_informativi";
  }

  toBacheca() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/utente/fascicolo/bacheca`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/utente/fascicolo/bacheca`;
  }

  toScadenze() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/utente/fascicolo/scadenze`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/utente/fascicolo/scadenze`;
  }

  toDomande() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/utente/fascicolo/domande`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/utente/fascicolo/domande`;
  }

  toPagamenti() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/utente/fascicolo/pagamenti`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/utente/fascicolo/pagamenti`;
  }

  toAvvisi() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/utente/fascicolo/avvisi`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/utente/fascicolo/avvisi`;
  }

  toPreferiti() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/utente/fascicolo/preferiti`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/utente/fascicolo/preferiti`;
  }

  toProfilo() {
    /* window.open(`https://mypa.mycity.collaudo.cloudeng.it/mypa/a/utente/profilo/profilo`, '_blank'); */
    window.location.href = `${this.baseUrlMyPa}/utente/profilo/profilo`;
  }

}
