import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TableColumn, OverlaySpinnerService, manageError, PaginatorData } from 'projects/mypa-fe-common/src/public-api';
import { Component, OnInit, ElementRef } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ricerca-posizione-aperta',
  templateUrl: './ricerca-posizione-aperta.component.html',
  styleUrls: ['./ricerca-posizione-aperta.component.scss']
})
export class RicercaPosizioneApertaComponent implements OnInit {

  public iconArrowLeft: any = faArrowLeft;

  public form: FormGroup;
  public tableColumns: TableColumn[] = [
    new TableColumn('nomeAssociazione', 'Associazione'),
    new TableColumn('posizione', 'Posizione'),
    new TableColumn('settore', 'Settore'),
    new TableColumn('attivita', 'Attività'),
    new TableColumn('destinatari', 'Destinatari'),
  ];
  public tableData: any[];
  public paginatorData: PaginatorData;

  constructor(protected formBuilder: FormBuilder,
    protected overlaySpinnerService: OverlaySpinnerService,
    protected elementRef: ElementRef,
    protected toastrService: ToastrService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      ente: [null, null],
      denominazione: [null, null],
      strutturaComunale: [null, null],
      settore: [null, null],
      attivita: [null, null],
      destinatari: [null, null],
    });
  }

  public onSubmit(): void {
    console.log(this.form);
    this.tableData = [
      {
        id: 1,
        nomeAssociazione: "Associazione 1",
        posizione: "Autista ambulanza",
        settore: "Sanita",
        attivita: "Attività culturali",
        destinatari: "Maggiorenni",
        details: [
          {
            key: 'Procedi alla candidatura',
            value: 1,
          },
        ],
      },
      {
        id: 2,
        nomeAssociazione: "Associazione 2",
        posizione: "Barelliere soccorritore",
        settore: "Sanita",
        attivita: "Servizi di assistenza sociale",
        destinatari: "Maggiorenni",
        details: [
          {
            key: 'Procedi alla candidatura',
            value: 2,
          },
        ],
      },
      {
        id: 3,
        nomeAssociazione: "Associazione 3",
        posizione: "Aiuto veterinario",
        settore: "Tutela dei diritti e attivita",
        attivita: "Protezione animali",
        destinatari: "Giovani laureati in medicina veterinaria",
        details: [
          {
            key: 'Procedi alla candidatura',
            value: 3,
          },
        ],
      },
      {
        id: 4,
        nomeAssociazione: "Associazione 4",
        posizione: "Professore matematica",
        settore: "Istruzione e ricerca",
        attivita: "Addestramento, avviamento professionale e inserimento lavorativo",
        destinatari: "Laureati in matematica",
        details: [
          {
            key: 'Procedi alla candidatura',
            value: 4,
          },
        ],
      },
      {
        id: 5,
        nomeAssociazione: "Associazione 5",
        posizione: "Teologo",
        settore: "Religione",
        attivita: "Attivita di religione e culto",
        destinatari: "Giovani",
        details: [
          {
            key: 'Procedi alla candidatura',
            value: 5,
          },
        ],
      },
      {
        id: 6,
        nomeAssociazione: "Associazione 6",
        posizione: "Allenatore di basket",
        settore: "Cultura, sport e ricreazione",
        attivita: "Attivita sportive",
        destinatari: "Esperti del gioco",
        details: [
          {
            key: 'Procedi alla candidatura',
            value: 6,
          },
        ],
      },
    ];
    /* const spinner = this.overlaySpinnerService.showProgress(this.elementRef);
    let searchAvvisiFun: Observable<any>;
    searchAvvisiFun
      .subscribe(response => {
        const dovuti = <Debito[]>response.debiti;
        const pagati = <Pagato[]>response.pagati;
        dovuti.forEach(element => {
          element.details = [
            { key: 'Causale', value: element.causale },
            { key: 'Numero avviso', value: element.numeroAvviso },
            { key: 'Intestatario avviso', value: element.intestatarioAvviso },
          ];
        })
        pagati.forEach(element => {
          const dovuto = new Debito();
          dovuto.dovutoElaborato = element;
          dovuto.deEnte = element.enteDeNome;
          dovuto.deTipoDovuto = element.deTipoDovuto;
          dovuto.causale = element.causale;
          dovuto.importo = element.importo;
          dovuto.dataScadenza = element.dataScadenza;
          dovuto.codStato = element.codStato;
          dovuto.deStato = element.statoComplessivo;
          dovuto.details = [
            { key: 'Causale', value: element.causale },
            { key: 'Numero avviso', value: element.codIuv },
            { key: 'Intestatario avviso', value: element.intestatario },
            //{key:'Stato dettaglio', value:element.stato}
          ];
          dovuti.push(dovuto);
        })
        this.tableData = dovuti;
        this.overlaySpinnerService.detach(spinner);
      }, manageError('Errore effettuando la ricerca', this.toastrService, () => { this.overlaySpinnerService.detach(spinner) })); */
  }

}
