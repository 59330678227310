

import { Component, OnInit } from '@angular/core';

import { Breadcrumb } from '../../model/breadcrumb';
import { MyPayBreadcrumbsService } from '../../services/my-pay-breadcrumbs.service';

@Component({
  selector: 'my-pay-breadcrumbs',
  templateUrl: './my-pay-breadcrumbs.component.html',
  styleUrls: ['./my-pay-breadcrumbs.component.scss']
})
export class MyPayBreadcrumbsComponent implements OnInit {

  breadcrumbs: Breadcrumb[] = null;

  constructor(
    private myPayBreadcrumbsService: MyPayBreadcrumbsService,
  ) {
    myPayBreadcrumbsService.getBreadcrumbs().subscribe(breadcrumbs => this.breadcrumbs = breadcrumbs);
  }

  ngOnInit(): void {
  }

  onClickBreadcrumb(breadcrumb: Breadcrumb, goBackFor: number){
    console.log(goBackFor, breadcrumb);
    if(goBackFor===0)
      return;
    this.myPayBreadcrumbsService.goBack(goBackFor);
  }
}
