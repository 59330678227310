import { first } from 'rxjs/operators';

import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Directive({
  selector: '[app-confirm]'
})
export class AppConfirmDirective {
  @Output("clickConfirmed") clickConfirmed: EventEmitter<any> = new EventEmitter();
  @Input('app-confirm') message: string;

  constructor(private dialog: MatDialog) { }

  @HostListener('click', ['$event'])
  onClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.dialog.open(ConfirmDialogComponent,{autoFocus:false, data: {message: this.message}})
    .afterClosed().pipe(first()).subscribe(result => {
      if(result==="true")
        this.clickConfirmed.next(e);
    });
  }
}
