import {
    ApiInvokerService, ConfigurationService, environment
} from 'projects/mypa-fe-common/src/public-api';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Comune, Nazione, Provincia } from '../model/location';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private baseApiUrl: string;

  constructor(
    private apiInvokerService: ApiInvokerService,
    conf: ConfigurationService
  ) {
    this.baseApiUrl = conf.getProperty('baseApiUrl', environment);
  }

  //cache values
  private nazioni: Nazione[];
  private province: Provincia[];
  private comuni: Comune[][]=[];

  getNazioni(): Observable<Nazione[]> {
    return this.nazioni ? of(this.nazioni) :
      this.apiInvokerService.get<Nazione[]>(this.baseApiUrl + 'public/location/nazioni')
        .pipe(map(nazioni => {this.nazioni=nazioni; return nazioni} ));
  }

  getProvince(): Observable<Provincia[]> {
    return this.province ? of(this.province) :
      this.apiInvokerService.get<Provincia[]>(this.baseApiUrl + 'public/location/province')
        .pipe(map(province => {this.province=province; return province} ));
  }

  getComuni(provincia: Provincia): Observable<Comune[]> {
    return this.comuni[provincia.provinciaId] ? of(this.comuni[provincia.provinciaId]) :
      this.apiInvokerService.get<Comune[]>(this.baseApiUrl + 'public/location/comuni/' + provincia.provinciaId)
        .pipe(map(comuni => {this.comuni[provincia.provinciaId]=comuni; return comuni} ));
  }
}
