<!-- <button style="margin-left: 1%;" type="button" mat-flat-button color="primary" (click)="indietro()">
    <fa-icon aria-hidden="true" [icon]="iconArrowLeft" style="margin-right: 5%;"></fa-icon>Torna alla ricerca
</button> -->

<br>
<br>

<div style="margin-bottom: 20%;" class="container">
    <div fxFlex fxLayout="column" fxLayoutAlign="start center">
        <p *ngIf="tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
        <my-pay-table-cittadino [tableData]="tableData" [tableColumns]="tableColumns" [parentRef]="this"
            [paginatorData]="paginatorData">
        </my-pay-table-cittadino>
    </div>
</div>