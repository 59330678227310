import { DettaglioConsensoComponent } from './../components/dettaglio-consenso/dettaglio-consenso.component';
import { DettaglioPosizioneApertaComponent } from './../components/dettaglio-posizione-aperta/dettaglio-posizione-aperta.component';
import { MyPaRicercaPosizioneApertaComponent } from './../components/my-pa-ricerca-posizione-aperta/my-pa-ricerca-posizione-aperta.component';
import { RicercaPosizioneApertaComponent } from './../components/ricerca-posizione-aperta/ricerca-posizione-aperta.component';
import { EsitoRicercaAssociazioneComponent } from './../components/esito-ricerca-associazione/esito-ricerca-associazione.component';
import { DettaglioAssociazioneComponent } from './../components/dettaglio-associazione/dettaglio-associazione.component';
import { AccessGuard } from 'projects/mypa-fe-common/src/public-api';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  LoggedComponent
} from '../../../../mypa-fe-common/src/lib/components/logged/logged.component';
import { AvvisiComponent } from '../components/avvisi/avvisi.component';
import { CardsComponent } from '../components/cards/cards.component';
import { CarrelloComponent } from '../components/carrello/carrello.component';
import { DebitiComponent } from '../components/debiti/debiti.component';
import { HomeComponent } from '../components/home/home.component';
import { LandingComponent } from '../components/landing/landing.component';
import { PagatiComponent } from '../components/pagati/pagati.component';
import { SpontaneoComponent } from '../components/spontaneo/spontaneo.component';
import { CharityEsitoComponent } from '../components/your/charity-esito/charity-esito.component';
import { CharityComponent } from '../components/your/charity/charity.component';

const routes = [
  /* rotte nuove */
  { path: 'esito-ricerca-associazione/:ente', component: EsitoRicercaAssociazioneComponent, data: { breadcrumb: { label: "Elenco" } } },
  { path: 'dettaglio-associazione/:id', component: DettaglioAssociazioneComponent, data: { breadcrumb: { label: "Dettaglio associazione/struttura" } } },
  { path: 'ricerca-posizione-aperta', component: RicercaPosizioneApertaComponent, data: { breadcrumb: { label: "Ricerca posizione aperta" } } },
  { path: 'my-pa-ricerca-posizione-aperta/:ente', component: MyPaRicercaPosizioneApertaComponent, data: { breadcrumb: { label: "Ricerca posizione aperta" } } },
  { path: 'dettaglio-posizione-aperta/:id/:ente', component: DettaglioPosizioneApertaComponent, data: { breadcrumb: { label: "Dettaglio posizione aperta" } }, canActivate: [AccessGuard] },
  { path: 'invia-consenso-privacy/:idAssociazione/:ente', component: DettaglioConsensoComponent, data: { breadcrumb: { label: "Dettaglio consenso" } }, canActivate: [AccessGuard] },

  /* rotte già presenti */
  { path: 'home', component: HomeComponent, data: { menuItemId: 20, breadcrumb: { home: true, label: "Home" } } },
  { path: 'logged', component: LoggedComponent, data: { redirectTo: 'cards' } },
  { path: 'landing/:type', component: LandingComponent },
  { path: 'cards', component: CardsComponent, data: { menuItemId: 21, requiresLogin: true, breadcrumb: { home: true, label: "Home" } }/* , canActivate: [AccessGuard] */ },
  { path: 'debiti', component: DebitiComponent, data: { menuItemId: 22, requiresLogin: true, breadcrumb: { label: "Posizioni aperte" } }/* , canActivate: [AccessGuard] */ },
  { path: 'pagati', component: PagatiComponent, data: { menuItemId: 23, requiresLogin: true, breadcrumb: { label: "Archivio pagamenti" } }/* , canActivate: [AccessGuard] */ },
  { path: 'avvisi', component: AvvisiComponent, data: { menuItemId: 24, breadcrumb: { label: "Avvisi di pagamento" } } },
  { path: 'spontaneo', component: SpontaneoComponent, data: { menuItemId: 32, breadcrumb: { label: "Altre tipologie di pagamento" } } },
  { path: 'carrello', component: CarrelloComponent, data: { menuItemId: 26, breadcrumb: { label: "Carrello" } } },
  { path: '', redirectTo: 'cards', pathMatch: 'full' },
  { path: 'charity', component: CharityComponent },
  { path: 'charity/esito', component: CharityEsitoComponent },
  { path: '**', redirectTo: 'cards', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})

export class AppRoutingModule { }
