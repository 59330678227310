<fa-icon [icon]="iconQuestionCircle" (click)="openHelp(helpdialog)"></fa-icon>

<ng-template #helpdialog>
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="start start">
    <h2>Help online</h2>
    <span class="flex-spacer"></span>
    <button mat-flat-button mat-dialog-close><fa-icon [icon]="iconTimes" size="lg"></fa-icon></button>
  </div>

  <mat-dialog-content>
    <ng-container *ngTemplateOutlet="templateRef"></ng-container>
  </mat-dialog-content>
</ng-template>
