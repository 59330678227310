import { ActivatedRoute } from '@angular/router';
import { Consenso } from './../../model/model-mypa-volontariato';
import { MypaVolontariatoService } from './../../services/mypa-volontariato/mypa-volontariato.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dettaglio-consenso',
  templateUrl: './dettaglio-consenso.component.html',
  styleUrls: ['./dettaglio-consenso.component.scss']
})
export class DettaglioConsensoComponent implements OnInit {

  public form: FormGroup;
  public premutoConferma: boolean = false;
  public mostraEsitoPositivo: boolean = false;
  public mostraEsitoNegativo: boolean = false;
  public mostraErroreGetConsenso: boolean = false;
  public consenso: Consenso = new Consenso();
  public idAssociazione: number;
  public ente: string;

  constructor(protected formBuilder: FormBuilder,
    private myPaVolontariatoService: MypaVolontariatoService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.idAssociazione = Number(this.activatedRoute.snapshot.paramMap.get("idAssociazione"));
    this.ente = this.activatedRoute.snapshot.paramMap.get("ente");
    this.buildForm(this.consenso);
    this.getConsenso();
  }

  public buildForm(consenso: Consenso): void {
    this.form = this.formBuilder.group({
      consenso: [consenso && consenso.consenso ? consenso.consenso : false, Validators.required],
    });
  }

  public getConsenso(): void {
    this.myPaVolontariatoService.getInfoConsenso(this.idAssociazione, this.ente).subscribe(
      response => {
        if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
          this.consenso = response.entity;
          this.buildForm(this.consenso);
        }
        else {
          this.mostraErroreGetConsenso = true;
        }
      }
    );
  }

  public confermaScelta(): void {
    this.mostraEsitoPositivo = false;
    this.mostraEsitoNegativo = false;
    this.mostraErroreGetConsenso = false;
    this.premutoConferma = true;
    if (this.form.valid) {
      this.myPaVolontariatoService.richiestaConsenso(this.idAssociazione, this.form.get("consenso").value, this.ente).subscribe(
        response => {
          if (response.esitoOperazione && response.esitoOperazione.codiceEsito === "0") {
            this.mostraEsitoPositivo = true;
          }
          else {
            this.mostraEsitoNegativo = true;
          }
        }
      );
    }
  }

}
